import TextField from '@mui/material/TextField/TextField';
import Typography from '@mui/material/Typography/Typography';
import React, { useEffect, useState } from 'react';
import { TTreeItemDataViewModel } from '../../services/models/CourseViewModels';
import { useStore } from '../../store/store';
import { observer } from 'mobx-react';
import { useTheme } from '@mui/material/styles';
import { runInAction } from 'mobx';

interface ITitleProps {
  className?: string;
  data: TTreeItemDataViewModel;
  coursePageID?: string;
  topBar?: boolean;
  fontSize?: number;
}
export default observer(function Title({ data, className, coursePageID, topBar, fontSize = 14 }: ITitleProps) {
  const { propertyStore, courseStore } = useStore();
  const { setPropertyByValueID } = propertyStore;
  const { newPageCoursePageID, resetNewPageCoursePageID } = courseStore;
  const [editTitleMode, setEditTitleMode] = useState(false);
  const theme = useTheme();

  const onTitleChange = (newTitle: string) => {
    runInAction(() => {
      data.title.value = newTitle;
    });
  };

  const updateTitle = () => {
    if (editTitleMode) {
      setPropertyByValueID(data.title.propertyValueID, data.title.value);
    }
    setEditTitleMode(false);
    resetNewPageCoursePageID();
  };

  const handleOnKeyDown = (e: any) => {
    if (editTitleMode && e.keyCode === 13) {
      updateTitle();
    }
  };

  const handleOnClick = (e: any) => {
    e.preventDefault();
    e.stopPropagation();
    setEditTitleMode(true);
  };

  const handleOnDoubleClick = (e: any) => {
    e.preventDefault();
    e.stopPropagation();
  };

  const handleTextFieldClick = (event: React.MouseEvent<HTMLInputElement>) => {
    event.stopPropagation();
    // Handle TextField click
  };

  useEffect(() => {
    setEditTitleMode(coursePageID === newPageCoursePageID ? true : false);
  }, []);
  return (
    <>
      {!editTitleMode ? (
        <div onClick={handleOnClick} onDoubleClick={handleOnDoubleClick} style={{ minWidth: 100, minHeight: 20, cursor: 'text' }}>
          <Typography variant='body2' fontSize={fontSize} className={className} noWrap style={{ textAlign: topBar ? 'center' : 'left', maxWidth: '700px' }}>
            {data.title?.value}
          </Typography>
        </div>
      ) : (
        <>
          <TextField
            data-pagetour='2'
            variant={'standard'}
            size={'small'}
            inputProps={{ style: { fontSize: fontSize } }}
            hiddenLabel={true}
            defaultValue={data.title?.value}
            onClick={handleTextFieldClick}
            onChange={(e: any) => onTitleChange(e.target?.value)}
            onKeyDown={handleOnKeyDown}
            onBlur={updateTitle}
            fullWidth
            autoFocus
            className={className}
            onFocus={(event) => {
              event.target.select();
            }}
            sx={{ input: { color: topBar ? theme.palette.common.white : theme.palette.text.primary } }}
          />
        </>
      )}
    </>
  );
});
