import React, { useEffect, useState } from 'react';
import TextField from '@mui/material/TextField/TextField';
import { runInAction } from 'mobx';
import { IPagePlaceholder } from '../../services/models/PageViewModel';
import { useStore } from '../../store/store';
import { observer } from 'mobx-react';
import { IPropertyGroup, IPropertyItems, IPropertyValue, TPropertyValueView } from '../../services/models/PropertyViewModel';
import IconButton from '@mui/material/IconButton/IconButton';
import LockOpenIcon from '@mui/icons-material/LockOpen';
import LockIcon from '@mui/icons-material/Lock';
import Accordion from '../../components/accordion/Accordion';
import { useTheme } from '@mui/material/styles';
import { AddStyle } from '../properties/placeholderProperties/PlaceholderProperties';
import DeleteIcon from '@mui/icons-material/Delete';
import { BiggerTooltip } from '../../components/tooltip/BiggerTooltip';

interface IPaddingSliderProps {
  propertyGroup: IPropertyGroup;
  paddingLeftProperty: TPropertyValueView;
  paddingRightProperty: TPropertyValueView;
  paddingTopProperty: TPropertyValueView;
  paddingBottomProperty: TPropertyValueView;
  styleName: string;
  placeholder: IPagePlaceholder;
}
export default observer(function PaddingProperty({ propertyGroup, paddingLeftProperty, paddingRightProperty, paddingTopProperty, paddingBottomProperty, placeholder }: IPaddingSliderProps) {
  const theme = useTheme();
  const { propertyStore } = useStore();
  const { setDesignProperty, setDesignPropertyList } = propertyStore;
  const [isMouseDown, setIsMouseDown] = useState(false);
  const [isPaddingLocked, setIsPaddingLocked] = useState(false);
  const [paddingLeft, setPaddingLeft] = useState(0);
  const [paddingRight, setPaddingRight] = useState(0);
  const [paddingTop, setPaddingTop] = useState(0);
  const [paddingBottom, setPaddingBottom] = useState(0);

  useEffect(() => {
    try {
      setPaddingLeft(parseInt(paddingLeftProperty.option.value.slice(0, -2)));
    } catch {
      console.log('could not parse paddingLeft.');
    }
    try {
      setPaddingRight(parseInt(paddingRightProperty.option.value.slice(0, -2)));
    } catch {
      console.log('could not parse paddingRight.');
    }
    try {
      setPaddingTop(parseInt(paddingTopProperty.option.value.slice(0, -2)));
    } catch {
      console.log('could not parse paddingTop.');
    }
    try {
      setPaddingBottom(parseInt(paddingBottomProperty.option.value.slice(0, -2)));
    } catch {
      console.log('could not parse paddingBottom.');
    }
  }, []);

  const handleChange = (styleName: string, aValue: string, action: (n: number) => void) => {
    let number = 0;
    try {
      if (aValue === '') aValue = '0';
      number = parseInt(aValue);
    } catch {
      console.log('unable to parse');
      number = paddingTop;
    }
    if (isPaddingLocked) {
      setPaddingLeft(number);
      setPaddingRight(number);
      setPaddingTop(number);
      setPaddingBottom(number);
    }
    action(number);
    updatePadding(styleName, number);
    updatePaddingProperty(styleName, number);
    if (isMouseDown) return;
  };

  const handleMouseUp = (styleName: string, aValue: number) => {
    setIsMouseDown(false);
    if (aValue.toString() === '') {
      aValue = 0;
    }
    updatePaddingProperty(styleName, aValue);
  };

  const updatePadding = (styleName: string, aValue: number) => {
    runInAction(() => {
      if (!isPaddingLocked) {
        propertyGroup.items[styleName].option.value = aValue + 'px';
        placeholder.colorAndDesign[styleName] = aValue + 'px';
      } else {
        placeholder.colorAndDesign.paddingLeft =
          propertyGroup.items.paddingLeft.option.value =
          placeholder.colorAndDesign.paddingRight =
          propertyGroup.items.paddingRight.option.value =
          placeholder.colorAndDesign.paddingTop =
          propertyGroup.items.paddingTop.option.value =
          placeholder.colorAndDesign.paddingBottom =
          propertyGroup.items.paddingBottom.option.value =
            aValue + 'px';
      }
    });
  };

  const updatePaddingProperty = (styleName: string, aValue: number) => {
    if (!isPaddingLocked) {
      setDesignProperty(propertyGroup.items[styleName], aValue + 'px');
    } else {
      const propertyList: IPropertyValue[] = [
        { propertyValueView: propertyGroup.items.paddingLeft, value: aValue + 'px' },
        { propertyValueView: propertyGroup.items.paddingRight, value: aValue + 'px' },
        { propertyValueView: propertyGroup.items.paddingTop, value: aValue + 'px' },
        { propertyValueView: propertyGroup.items.paddingBottom, value: aValue + 'px' },
      ];
      setDesignPropertyList(propertyGroup, propertyList);
    }
  };

  const addPadding = () => {
    if (placeholder !== undefined) {
      runInAction(() => {
        paddingLeftProperty.option.value =
          paddingRightProperty.option.value =
          paddingTopProperty.option.value =
          paddingBottomProperty.option.value =
          placeholder.colorAndDesign.paddingLeft =
          placeholder.colorAndDesign.paddingRight =
          placeholder.colorAndDesign.paddingTop =
          placeholder.colorAndDesign.paddingBottom =
            '15px';

        const propertyList: IPropertyValue[] = [
          { propertyValueView: paddingLeftProperty, value: '15px' },
          { propertyValueView: paddingRightProperty, value: '15px' },
          { propertyValueView: paddingTopProperty, value: '15px' },
          { propertyValueView: paddingBottomProperty, value: '15px' },
        ];
        setDesignPropertyList(propertyGroup, propertyList);

        setPaddingTop(10);
        setPaddingLeft(10);
        setPaddingRight(10);
        setPaddingBottom(10);
      });
    }
  };

  const handleRemovePadding = () => {
    runInAction(() => {
      paddingLeftProperty.option.value =
        paddingRightProperty.option.value =
        paddingTopProperty.option.value =
        paddingBottomProperty.option.value =
        placeholder.colorAndDesign.paddingLeft =
        placeholder.colorAndDesign.paddingRight =
        placeholder.colorAndDesign.paddingTop =
        placeholder.colorAndDesign.paddingBottom =
          '';

      const propertyList: IPropertyValue[] = [
        { propertyValueView: paddingLeftProperty, value: '' },
        { propertyValueView: paddingRightProperty, value: '' },
        { propertyValueView: paddingTopProperty, value: '' },
        { propertyValueView: paddingBottomProperty, value: '' },
      ];
      setDesignPropertyList(propertyGroup, propertyList);
    });
  };

  useEffect(() => {
    if (isPaddingLocked) {
      setPaddingLeft(paddingTop);
      setPaddingRight(paddingTop);
      setPaddingBottom(paddingTop);
      runInAction(() => {
        placeholder.colorAndDesign.paddingLeft = paddingTop.toString() + 'px';
        placeholder.colorAndDesign.paddingRight = paddingTop.toString() + 'px';
        placeholder.colorAndDesign.paddingBottom = paddingTop.toString() + 'px';
      });

      const propertyList: IPropertyValue[] = [
        { propertyValueView: paddingLeftProperty, value: paddingTop.toString() + 'px' },
        { propertyValueView: paddingRightProperty, value: paddingTop.toString() + 'px' },
        { propertyValueView: paddingBottomProperty, value: paddingTop.toString() + 'px' },
      ];
      setDesignPropertyList(propertyGroup, propertyList);
    }
  }, [isPaddingLocked]);

  if (paddingLeftProperty.option.value === '' && paddingRightProperty.option.value === '' && paddingTopProperty.option.value === '' && paddingBottomProperty.option.value === '') {
    return <AddStyle styleName={'Padding'} addStyle={addPadding} />;
  } else {
    return (
      <Accordion title={'Padding'}>
        <BiggerTooltip title={'Remove padding'}>
          <IconButton onClick={handleRemovePadding} style={{ position: 'absolute', top: 0, right: 0 }}>
            <DeleteIcon />
          </IconButton>
        </BiggerTooltip>
        <div style={{ display: 'grid', gridTemplateRows: 'auto ', gridTemplateColumns: '70px 100px 70px ', gap: theme.spacing(1) }}>
          <div></div>
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <TextField
              type='number'
              value={paddingTop}
              aria-label='padding-top'
              InputProps={{ inputProps: { min: 0, style: { textAlign: 'center' } } }}
              onChange={(e: any) => handleChange('paddingTop', e.target.value, setPaddingTop)}
              variant='standard'
              style={{ width: 45 }}
              autoComplete={'off'}
              onMouseDown={() => setIsMouseDown(true)}
              onMouseUp={(e: any) => handleMouseUp('paddingTop', e.target.value)}
            />
          </div>
          <div></div>
          <div style={{ display: 'flex', justifyContent: 'end', alignItems: 'center' }}>
            <TextField
              type='number'
              value={paddingLeft}
              aria-label='padding-left'
              InputProps={{ inputProps: { min: 0, style: { textAlign: 'center' } } }}
              onChange={(e: any) => handleChange('paddingLeft', e.target.value, setPaddingLeft)}
              variant='standard'
              style={{ width: 45 }}
              autoComplete={'off'}
              onMouseDown={() => setIsMouseDown(true)}
              onMouseUp={(e: any) => handleMouseUp('paddingLeft', e.target.value)}
            />
          </div>
          <div>
            <div
              style={{
                width: 100,
                aspectRatio: '16/9',
                background: 'transparent',
                borderWidth: 2,
                borderStyle: 'solid',
                borderColor: theme.palette.border.main,
                display: 'grid',
                placeContent: 'center',
              }}
            >
              <BiggerTooltip title={'Lock all paddings to be the same'}>
                <IconButton onClick={() => setIsPaddingLocked(!isPaddingLocked)}>{isPaddingLocked ? <LockIcon /> : <LockOpenIcon />}</IconButton>
              </BiggerTooltip>
            </div>
          </div>
          <div style={{ display: 'flex', justifyContent: 'start', alignItems: 'center' }}>
            <TextField
              type='number'
              value={paddingRight}
              aria-label='padding-right'
              InputProps={{ inputProps: { min: 0, style: { textAlign: 'center' } } }}
              onChange={(e: any) => handleChange('paddingRight', e.target.value, setPaddingRight)}
              variant='standard'
              style={{ width: 45 }}
              autoComplete={'off'}
              onMouseDown={() => setIsMouseDown(true)}
              onMouseUp={(e: any) => handleMouseUp('paddingRight', e.target.value)}
            />
          </div>
          <div></div>
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <TextField
              type='number'
              value={paddingBottom}
              aria-label='padding-bottom'
              InputProps={{ inputProps: { min: 0, style: { textAlign: 'center' } } }}
              onChange={(e: any) => handleChange('paddingBottom', e.target.value, setPaddingBottom)}
              variant='standard'
              style={{ width: 45 }}
              autoComplete={'off'}
              onMouseDown={() => setIsMouseDown(true)}
              onMouseUp={(e: any) => handleMouseUp('paddingBottom', e.target.value)}
            />
          </div>
          <div></div>
        </div>
      </Accordion>
    );
  }
});
