import Tree, { moveItemOnTree, mutateTree } from '@atlaskit/tree';
import { RenderItemParams } from '@atlaskit/tree/dist/types/components/TreeItem/TreeItem-types';
import { ItemId, TreeData, TreeDestinationPosition, TreeSourcePosition } from '@atlaskit/tree/dist/types/types';
import { useSnackbar } from 'notistack';
import { observer } from 'mobx-react';
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { TTreeItemDataViewModel, TTreeViewModel } from '../../../services/models/CourseViewModels';
import { useStore } from '../../../store/store';
import CoursePageItem from './CoursePageItem';
import CoursePageItemCompact from './CoursePageItemCompact';
import { coursePageStyles } from './CoursePageStyle';
import { checkNumberOfChildren, checkNumberOfParents } from '../../../utils/courseDashboard/coureDashboardUtils';
import { useTheme } from '@mui/material/styles';

export interface ICoursePageListProps {
  pages: TTreeViewModel;
  handleNewPageItem: (item: TreeItem) => void;
  handlePastePage: (item: TreeItem) => void;
  handleDuplicatePageItem: (item: TreeItem, copyChildren: boolean) => void;
  compact?: boolean;
  pageID?: number;
  height: number;
  handleClose?: () => void;
  image?: boolean;
}

export interface TreeItem {
  id: ItemId;
  children: ItemId[];
  hasChildren?: boolean;
  isExpanded?: boolean;
  isChildrenLoading?: boolean;
  data?: TTreeItemDataViewModel;
}

export interface WrapperProps {
  item: TreeItem;
  isCompact: boolean;
  children?: React.ReactNode;
}

export default observer(function CoursePageList({ handleNewPageItem, handleDuplicatePageItem, handlePastePage, pages, compact, height, pageID, handleClose, image }: ICoursePageListProps) {
  const [isDragging, setIsDragging] = useState(false);
  const { courseStore, pageStore } = useStore();
  const { courseViewModel, movePage } = courseStore;
  const { setExpanded } = pageStore;
  const [treeData, setTreeData] = useState<TreeData>(pages);
  const { enqueueSnackbar } = useSnackbar();
  const theme = useTheme();

  const classes = coursePageStyles({ theme });

  const selectedPage = pageID ? pageID : 0;

  const ItemWrapper = ({ item, isCompact, children }: WrapperProps) => {
    if (isCompact && handleClose) {
      return (
        <Link to={`/courseEdit?courseID=${courseViewModel != undefined ? courseViewModel.courseID : 0}&pageID=${item.id}`} onClick={handleClose} style={{ textDecoration: 'none' }}>
          <div className={selectedPage == parseInt(item.id.toString()) ? classes.pageCompactSelected : classes.pageCompact}>{children}</div>
        </Link>
      );
    } else {
      return <>{children}</>;
    }
  };

  const renderItem = ({ item, onExpand, onCollapse, provided, snapshot }: RenderItemParams) => {
    const data: TTreeItemDataViewModel = item.data;
    return (
      <ItemWrapper item={item} isCompact={compact ? compact : false}>
        <div ref={provided.innerRef} {...provided.draggableProps}>
          <div>
            {compact ? (
              <CoursePageItemCompact title={data.title} />
            ) : (
              <CoursePageItem
                provided={provided}
                item={item}
                onExpand={onExpand}
                onCollapse={onCollapse}
                snapshot={snapshot}
                isDragging={isDragging}
                image={image}
                handleNewPageItem={handleNewPageItem}
                handleDuplicatePageItem={handleDuplicatePageItem}
                handlePastePage={handlePastePage}
              />
            )}
          </div>
        </div>
      </ItemWrapper>
    );
  };

  const onExpand = (itemId: ItemId) => {
    setTreeData(mutateTree(treeData, itemId, { isExpanded: true }));
    if (courseViewModel) {
      setExpanded(courseViewModel.courseID, itemId + '', true);
    }
  };

  const onCollapse = (itemId: ItemId) => {
    setTreeData(mutateTree(treeData, itemId, { isExpanded: false }));
    if (courseViewModel) {
      setExpanded(courseViewModel.courseID, itemId + '', false);
    }
  };

  const onDragStart = () => {
    setIsDragging(true);
  };

  const onDragEnd = (source: TreeSourcePosition, destination?: TreeDestinationPosition) => {
    setIsDragging(false);
    if (!destination) {
      return;
    }

    if (destination) {
      const currentItem = treeData.items[source.parentId].children[source.index];
      const parentCount = checkNumberOfParents(treeData, destination.parentId);
      const childCount = checkNumberOfChildren(treeData, currentItem);
      if (parentCount + childCount < 3) {
        movePage(treeData.items[source.parentId].children[source.index].toString(), destination.parentId.toString(), destination.index != undefined ? destination.index : undefined);
        treeData.items[treeData.items[source.parentId].children[source.index]].data.parentCoursePageID = destination.parentId;
        treeData.items[destination.parentId].isExpanded = true;
        setTreeData(moveItemOnTree(treeData, source, destination));
      } else {
        // eslint-disable-next-line quotes
        enqueueSnackbar("You've reached the maximum nesting depth of two subpages. Paste at an elevated page or subpage.", {
          variant: 'info',
          autoHideDuration: 5000,
        });
      }
    }
  };

  useEffect(() => {
    if (courseViewModel != undefined) setTreeData(courseViewModel.pages);
  }, [courseViewModel]);

  return (
    <div style={{ width: compact ? '500px' : '100%', maxHeight: height, minHeight: compact ? 'auto' : height }} data-clickable='true'>
      <div style={{ padding: 0, paddingBottom: compact ? 0 : 20, paddingTop: compact ? 10 : 0 }} data-clickable='false'>
        <Tree
          tree={treeData}
          renderItem={renderItem}
          onExpand={onExpand}
          onCollapse={onCollapse}
          onDragEnd={onDragEnd}
          isDragEnabled={!compact}
          isNestingEnabled
          offsetPerLevel={60}
          onDragStart={onDragStart}
        />
      </div>
    </div>
  );
});
