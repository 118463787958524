import Button from '@mui/material/Button/Button';
import { useTheme } from '@mui/material/styles';
import TextField from '@mui/material/TextField/TextField';
import SearchIcon from '@mui/icons-material/Search';
import React, { useState } from 'react';
import { useStore } from '../../../store/store';

export default function UnsplashHeader() {
  const { unsplashStore } = useStore();
  const { SearchRandomUnsplash, SearchLatestUnsplash, SearchUnsplash } = unsplashStore;
  const [searchTerm, setSearchTerm] = useState('');
  const theme = useTheme();

  const handleSubmit = (e: any) => {
    if (e.charCode == 13) {
      SearchUnsplash(searchTerm);
    }
  };

  return (
    <>
      <div style={{ padding: theme.spacing(2) }}>
        <Button variant='newSecondary' size='small' sx={{ marginRight: 2 }} onClick={() => SearchRandomUnsplash()}>
          Random
        </Button>
        <TextField
          id='outlined'
          size='small'
          label='Search unsplash'
          variant='outlined'
          sx={{ width: 400 }}
          onChange={(e: any) => setSearchTerm(e.target.value)}
          onKeyPress={handleSubmit}
          InputProps={{
            startAdornment: <SearchIcon />,
          }}
          inputProps={{ style: { WebkitBoxShadow: '0 0 0 1000px transparent inset' } }}
        />
        <Button variant='newSecondary' size='small' sx={{ marginLeft: 2 }} onClick={() => SearchUnsplash(searchTerm)}>
          Search
        </Button>
      </div>
    </>
  );
}
