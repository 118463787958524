import React, { useState } from 'react';
import Accordion from '../../components/accordion/Accordion';
import { useTheme } from '@mui/material/styles';
import { IPagePlaceholder, TPageViewModel } from '../../services/models/PageViewModel';
import { runInAction } from 'mobx';
import { useStore } from '../../store/store';
import { AddStyle } from '../properties/placeholderProperties/PlaceholderProperties';
import { IPropertyGroupColor, IPropertyValue } from '../../services/models/PropertyViewModel';
import { observer } from 'mobx-react';
import IconButton from '@mui/material/IconButton/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import { BiggerTooltip } from '../../components/tooltip/BiggerTooltip';
import Divider from '@mui/material/Divider/Divider';
import ColorPicker from '../../components/color/ColorPicker';

interface IBackgroundColorPropertyProps {
  propertyGroup: IPropertyGroupColor;
  placeholder?: IPagePlaceholder;
  page?: TPageViewModel;
}
export default observer(function BackgroundColorProperty({ propertyGroup, placeholder, page }: IBackgroundColorPropertyProps) {
  const theme = useTheme();
  const [defaultOpen, setDefaultOpen] = useState(false);
  const { propertyStore } = useStore();
  const { setDesignPropertyTimer, setDesignProperty, setDesignPropertyList } = propertyStore;
  const group = { ...propertyGroup.items };
  const defaultColor = group.backgroundColor.option.value;

  const commitColor = (aColor: string) => {
    updateColor(aColor);
    setDesignPropertyTimer(group.backgroundColor, aColor);
    runInAction(() => {
      group.backgroundColor.option.value = aColor;
    });
  };

  const updateColor = (aColor: string) => {
    runInAction(() => {
      if (placeholder !== undefined) {
        placeholder.colorAndDesign.backgroundColor = aColor;
      }
      if (page !== undefined) {
        page.backgroundColor = aColor;
      }
    });
  };

  const handleRemoveBackgroundColor = () => {
    runInAction(() => {
      group.backgroundColor.option.value = '';
      setDesignPropertyTimer(group.backgroundColor, '');
      if (placeholder !== undefined) {
        placeholder.colorAndDesign.backgroundColor = '';
      }
      if (page !== undefined) {
        page.backgroundColor = '';
      }
    });
  };

  const handleAddBackgroundColor = () => {
    setDefaultOpen(true);
    runInAction(() => {
      handleRemoveBackgroundImage();
      group.backgroundColor.option.value = '#FFFFFFFF';
      setDesignPropertyTimer(group.backgroundColor, '#FFFFFFFF');
      if (placeholder !== undefined) {
        placeholder.colorAndDesign.backgroundColor = '#FFFFFFFF';
      }
      if (page !== undefined) {
        page.backgroundColor = '#FFFFFFFF';
      }
    });
  };

  const handleRemoveBackgroundImage = () => {
    runInAction(() => {
      group.backgroundImage.option.value = '';
      group.backgroundPosition.option.value = '';
      group.backgroundSize.option.value = '';
      group.backgroundRepeat.option.value = '';

      const propertyList: IPropertyValue[] = [
        { propertyValueView: group.backgroundImage, value: '' },
        { propertyValueView: group.backgroundPosition, value: '' },
        { propertyValueView: group.backgroundSize, value: '' },
        { propertyValueView: group.backgroundRepeat, value: '' },
      ];
      setDesignPropertyList(propertyGroup, propertyList);

      if (placeholder !== undefined) {
        placeholder.colorAndDesign.backgroundImage = '';
        placeholder.colorAndDesign.backgroundPosition = '';
        placeholder.colorAndDesign.backgroundSize = '';
        placeholder.colorAndDesign.backgroundRepeat = 't';
      }
      if (page !== undefined) {
        page.backgroundImage = '';
        page.backgroundPosition = '';
        page.backgroundSize = '';
        page.backgroundRepeat = '';
      }
    });
  };

  /*   if (backgroundImage.option.value.length > 0) {
    return <></>;
  } */
  if (group.backgroundColor.option.value === '' || group.backgroundImage.option.value.length > 0) {
    return (
      <>
        <Divider />
        <AddStyle styleName={'Background Color'} addStyle={handleAddBackgroundColor} />
      </>
    );
  } else {
    return (
      <>
        <Accordion title={'Background Color'} isOpen={defaultOpen}>
          <BiggerTooltip title={'Remove background color'}>
            <IconButton onClick={handleRemoveBackgroundColor} style={{ position: 'absolute', top: 0, right: 15 }}>
              <DeleteIcon />
            </IconButton>
          </BiggerTooltip>
          <div style={{ display: 'flex', flexDirection: 'column', gap: theme.spacing(4), paddingLeft: 14, paddingRight: 13, paddingTop: theme.spacing(5) }}>
            <ColorPicker defaultColor={defaultColor} onColorChange={updateColor} onCommitColor={commitColor} />
          </div>
        </Accordion>
      </>
    );
  }
});
