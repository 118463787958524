import { ICoursePageListProps, WrapperProps } from './CoursePageList';
import Tree, { moveItemOnTree, mutateTree } from '@atlaskit/tree';
import { RenderItemParams } from '@atlaskit/tree/dist/types/components/TreeItem/TreeItem-types';
import { ItemId, TreeData, TreeDestinationPosition, TreeSourcePosition } from '@atlaskit/tree/dist/types/types';
import { useSnackbar } from 'notistack';
import { observer } from 'mobx-react';
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useStore } from '../../../store/store';
import { coursePageStyles } from './CoursePageStyle';
import CoursePageGridItem from './CoursePageGridItem';
import { checkNumberOfChildren, checkNumberOfParents } from '../../../utils/courseDashboard/coureDashboardUtils';
import { useTheme } from '@mui/material/styles';

export default observer(function CoursePageGrid({ handleNewPageItem, handleDuplicatePageItem, handlePastePage, pages, compact, height, pageID, handleClose }: ICoursePageListProps) {
  const [isDragging, setIsDragging] = useState(false);
  const { courseStore } = useStore();
  const { courseViewModel, movePage } = courseStore;
  const [treeData, setTreeData] = useState<TreeData>(pages);
  const { enqueueSnackbar } = useSnackbar();
  const theme = useTheme();

  const classes = coursePageStyles({ theme });

  const selectedPage = pageID ? pageID : 0;

  const ItemWrapper = ({ item, isCompact, children }: WrapperProps) => {
    if (isCompact && handleClose) {
      return (
        <Link to={`/courseEdit?courseID=${courseViewModel != undefined ? courseViewModel.courseID : 0}&pageID=${item.id}`} onClick={handleClose} style={{ textDecoration: 'none' }}>
          <div className={selectedPage == parseInt(item.id.toString()) ? classes.pageCompactSelected : classes.pageCompact}>{children}</div>
        </Link>
      );
    } else {
      return <>{children}</>;
    }
  };

  const emptyFunc = () => {
    return;
  };

  const renderItem = ({ item, provided, snapshot }: RenderItemParams) => {
    return (
      <ItemWrapper item={item} isCompact={compact ? compact : false}>
        <div ref={provided.innerRef} {...provided.draggableProps}>
          <CoursePageGridItem
            provided={provided}
            item={item}
            handleNewPageItem={handleNewPageItem}
            handleDuplicatePageItem={handleDuplicatePageItem}
            handlePastePage={handlePastePage}
            snapshot={snapshot}
            isDragging={isDragging}
            onExpand={emptyFunc}
            onCollapse={emptyFunc}
          />
        </div>
      </ItemWrapper>
    );
  };

  const onExpand = (itemId: ItemId) => {
    setTreeData(mutateTree(treeData, itemId, { isExpanded: true }));
  };

  const onCollapse = (itemId: ItemId) => {
    setTreeData(mutateTree(treeData, itemId, { isExpanded: false }));
  };

  const onDragStart = () => {
    setIsDragging(true);
  };

  const onDragEnd = (source: TreeSourcePosition, destination?: TreeDestinationPosition) => {
    setIsDragging(false);
    if (!destination) {
      return;
    }

    if (destination) {
      const currentItem = treeData.items[source.parentId].children[source.index];
      const parentCount = checkNumberOfParents(treeData, destination.parentId);
      const childCount = checkNumberOfChildren(treeData, currentItem);
      if (parentCount + childCount < 3) {
        movePage(treeData.items[source.parentId].children[source.index].toString(), destination.parentId.toString(), destination.index != undefined ? destination.index : undefined);
        treeData.items[treeData.items[source.parentId].children[source.index]].data.parentCoursePageID = destination.parentId;
        treeData.items[destination.parentId].isExpanded = true;
        setTreeData(moveItemOnTree(treeData, source, destination));
      } else {
        enqueueSnackbar('You\'ve reached the maximum nesting depth of two subpages. Paste at an elevated page or subpage.', {
          variant: 'info',
          autoHideDuration: 5000,
        });
      }
    }
  };

  useEffect(() => {
    if (courseViewModel != undefined) setTreeData(courseViewModel.pages);
  }, [courseViewModel]);
  return (
    <div style={{ width: '100%', maxHeight: height, height, overflowY: 'scroll', overflowX: 'hidden' }} data-clickable='true'>
      <div style={{ padding: 0 }}>
        <Tree
          tree={treeData}
          renderItem={renderItem}
          onExpand={onExpand}
          onCollapse={onCollapse}
          onDragEnd={onDragEnd}
          isDragEnabled={!compact}
          isNestingEnabled
          offsetPerLevel={70}
          onDragStart={onDragStart}
        />
      </div>
    </div>
  );
});
