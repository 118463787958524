import React, { SVGProps } from 'react';

const AccordionIcon: React.FC<SVGProps<SVGSVGElement>> = () => {
  return (
    <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M4 2C2.89543 2 2 2.89543 2 4V6C2 7.10457 2.89543 8 4 8H20C21.1046 8 22 7.10457 22 6V4C22 2.89543 21.1046 2 20 2H4ZM17.5 7V5.5H16V4.5H17.5V3H18.5V4.5H20V5.5H18.5V7H17.5Z'
        fill='currentColor'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M4 9C2.89543 9 2 9.89543 2 11V13C2 14.1046 2.89543 15 4 15H20C21.1046 15 22 14.1046 22 13V11C22 9.89543 21.1046 9 20 9H4ZM17.5 14V12.5H16V11.5H17.5V10H18.5V11.5H20V12.5H18.5V14H17.5Z'
        fill='currentColor'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M4 16C2.89543 16 2 16.8954 2 18V20C2 21.1046 2.89543 22 4 22H20C21.1046 22 22 21.1046 22 20V18C22 16.8954 21.1046 16 20 16H4ZM17.5 21V19.5H16V18.5H17.5V17H18.5V18.5H20V19.5H18.5V21H17.5Z'
        fill='currentColor'
      />
    </svg>
  );
};

const AccordionSectionIcon: React.FC<SVGProps<SVGSVGElement>> = () => {
  return (
    <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M4 3.04993C2.89543 3.04993 2 3.94536 2 5.04993V9.04993H22V5.04993C22 3.94536 21.1046 3.04993 20 3.04993H4ZM20 5.54993V6.54993H16V5.54993H20Z'
        fill='currentColor'
      />
      <rect x='3' y='4' width='18' height='16' rx='1' stroke='currentColor' strokeWidth='2' />
    </svg>
  );
};

const SingleChoiceIcon: React.FC<SVGProps<SVGSVGElement>> = () => {
  return (
    <svg width='24' height='24' viewBox='0 0 24 24' fill='currentColor' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M12 2C6.48 2 2 6.48 2 12C2 17.52 6.48 22 12 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 12 2ZM12 20C7.58 20 4 16.42 4 12C4 7.58 7.58 4 12 4C16.42 4 20 7.58 20 12C20 16.42 16.42 20 12 20Z'
        fill='inherit'
      />
      <path d='M12 17C14.7614 17 17 14.7614 17 12C17 9.23858 14.7614 7 12 7C9.23858 7 7 9.23858 7 12C7 14.7614 9.23858 17 12 17Z' fill='inherit' />
    </svg>
  );
};
const SingleChoiceAlternativeIcon: React.FC<SVGProps<SVGSVGElement>> = () => {
  return (
    <svg width='24' height='24' viewBox='0 0 24 24' fill='currentColor' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M12 2C6.48 2 2 6.48 2 12C2 17.52 6.48 22 12 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 12 2ZM12 20C7.58 20 4 16.42 4 12C4 7.58 7.58 4 12 4C16.42 4 20 7.58 20 12C20 16.42 16.42 20 12 20Z'
        fill='inherit'
      />
    </svg>
  );
};

const MultipleChoiceIcon: React.FC<SVGProps<SVGSVGElement>> = () => {
  return (
    <svg width='24' height='24' viewBox='0 0 24 24' fill='currentColor' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M19 3H5C3.9 3 3 3.9 3 5V19C3 20.1 3.9 21 5 21H19C20.1 21 21 20.1 21 19V5C21 3.9 20.1 3 19 3ZM19 19H5V5H19V19ZM17.99 9L16.58 7.58L9.99 14.17L7.41 11.6L5.99 13.01L9.99 17L17.99 9Z'
        fill='inherit'
      />
    </svg>
  );
};
const MultipleChoiceAlternativeIcon: React.FC<SVGProps<SVGSVGElement>> = () => {
  return (
    <svg width='25' height='24' viewBox='0 0 25 24' fill='currentColor' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M19.5981 5V19H5.59814V5H19.5981ZM19.5981 3H5.59814C4.49814 3 3.59814 3.9 3.59814 5V19C3.59814 20.1 4.49814 21 5.59814 21H19.5981C20.6981 21 21.5981 20.1 21.5981 19V5C21.5981 3.9 20.6981 3 19.5981 3Z'
        fill='inherit'
      />
    </svg>
  );
};

const TrueFalseIcon: React.FC<SVGProps<SVGSVGElement>> = () => {
  return (
    <svg width='24' height='24' viewBox='0 0 24 24' fill='currentColor' xmlns='http://www.w3.org/2000/svg'>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M14.1459 18.9522L14.1317 18.9664L10.7214 15.5562L12.2276 14.05L14.1459 15.9682L17.684 12.4443L19.1902 13.9505L14.1601 18.9806L14.1459 18.9522Z'
        fill='inherit'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M4 20L20 4V20H4ZM2 4C2 2.89543 2.89543 2 4 2H20C21.1046 2 22 2.89543 22 4V20C22 21.1046 21.1046 22 20 22H4C2.89543 22 2 21.1046 2 20V4ZM7.50848 6.42884L5.50497 4.42533L3.99878 5.93152L6.00229 7.93503L3.99878 9.93854L5.50497 11.4447L7.50848 9.44121L9.51199 11.4447L11.0182 9.93854L9.01466 7.93503L11.0182 5.93152L9.51199 4.42533L7.50848 6.42884Z'
        fill='inherit'
      />
    </svg>
  );
};

const TrueFalseAlternativeIcon: React.FC<SVGProps<SVGSVGElement>> = () => {
  return (
    <svg width='24' height='24' viewBox='0 0 24 24' fill='currentColor' xmlns='http://www.w3.org/2000/svg'>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M20 4L4 20H20V4ZM4 2C2.89543 2 2 2.89543 2 4V20C2 21.1046 2.89543 22 4 22H20C21.1046 22 22 21.1046 22 20V4C22 2.89543 21.1046 2 20 2H4Z'
        fill='inherit'
      />
    </svg>
  );
};

const JustifyCenterIcon: React.FC<SVGProps<SVGSVGElement>> = () => {
  return (
    <svg width='24' height='24' viewBox='0 0 24 24' fill='currentColor' xmlns='http://www.w3.org/2000/svg'>
      <path d='M22 13H2V11H22V13Z' fill='inherit' />
      <path d='M7 14L7 17L17 17L17 14L7 14Z' fill='inherit' />
      <path d='M7 7L7 10L17 10L17 7L7 7Z' fill='inherit' />
    </svg>
  );
};
const JustifyStartIcon: React.FC<SVGProps<SVGSVGElement>> = () => {
  return (
    <svg width='24' height='24' viewBox='0 0 24 24' fill='currentColor' xmlns='http://www.w3.org/2000/svg'>
      <path d='M2 2L22 2L22 4L2 4L2 2Z' fill='inherit' />
      <path d='M17 8L17 5L7 5L7 8L17 8Z' fill='inherit' />
      <path d='M17 12L17 9L7 9L7 12L17 12Z' fill='inherit' />
    </svg>
  );
};
const JustifyEndIcon: React.FC<SVGProps<SVGSVGElement>> = () => {
  return (
    <svg width='24' height='24' viewBox='0 0 24 24' fill='currentColor' xmlns='http://www.w3.org/2000/svg'>
      <path d='M22 22H2V20H22V22Z' fill='inherit' />
      <path d='M7 16L7 19L17 19L17 16L7 16Z' fill='inherit' />
      <path d='M7 12L7 15L17 15L17 12L7 12Z' fill='inherit' />
    </svg>
  );
};
const JustifySpaceBetweenIcon: React.FC<SVGProps<SVGSVGElement>> = () => {
  return (
    <svg width='24' height='24' viewBox='0 0 24 24' fill='currentColor' xmlns='http://www.w3.org/2000/svg'>
      <path d='M22 22H2V20H22V22Z' fill='inherit' />
      <path d='M22 4H2V2H22V4Z' fill='inherit' />
      <path d='M7 17L7 20L17 20L17 17L7 17Z' fill='inherit' />
      <path d='M7 4L7 7L17 7L17 4L7 4Z' fill='inherit' />
    </svg>
  );
};
const JustifySpaceAroundIcon: React.FC<SVGProps<SVGSVGElement>> = () => {
  return (
    <svg width='24' height='24' viewBox='0 0 24 24' fill='currentColor' xmlns='http://www.w3.org/2000/svg'>
      <path d='M22 22H2V20H22V22Z' fill='inherit' />
      <path d='M22 4H2V2H22V4Z' fill='inherit' />
      <path d='M7 16L7 19L17 19L17 16L7 16Z' fill='inherit' />
      <path d='M7 5L7 8L17 8L17 5L7 5Z' fill='inherit' />
    </svg>
  );
};
const JustifySpaceEvenlyIcon: React.FC<SVGProps<SVGSVGElement>> = () => {
  return (
    <svg width='24' height='24' viewBox='0 0 24 24' fill='currentColor' xmlns='http://www.w3.org/2000/svg'>
      <path d='M22 22H2V20H22V22Z' fill='inherit' />
      <path d='M22 4H2V2H22V4Z' fill='inherit' />
      <path d='M7 14L7 17L17 17L17 14L7 14Z' fill='inherit' />
      <path d='M7 7L7 10L17 10L17 7L7 7Z' fill='inherit' />
    </svg>
  );
};

const FullscreenIcon: React.FC<SVGProps<SVGSVGElement>> = () => {
  return (
    <svg width='24' height='24' viewBox='0 0 24 24' fill='currentColor' xmlns='http://www.w3.org/2000/svg'>
      <path d='M7 14H5V19H10V17H7V14ZM5 10H7V7H10V5H5V10ZM17 17H14V19H19V14H17V17ZM14 5V7H17V10H19V5H14Z' fill='inherit' />
    </svg>
  );
};

const WikiIcon: React.FC<SVGProps<SVGSVGElement>> = () => {
  return (
    <svg width='24' height='24' viewBox='0 0 24 24' fill='currentColor' xmlns='http://www.w3.org/2000/svg'>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M4.95 21.05C5.58333 21.6833 6.35 22 7.25 22H20V20.5C19.5667 20.5 19.2083 20.325 18.925 19.975C18.6417 19.625 18.5 19.2167 18.5 18.75C18.5 18.2833 18.6417 17.875 18.925 17.525C19.2083 17.175 19.5667 17 20 17V2H7.25C6.35 2 5.58333 2.31667 4.95 2.95C4.31667 3.58333 4 4.35 4 5.25V18.75C4 19.65 4.31667 20.4167 4.95 21.05ZM17.475 20.5H7.25C6.76667 20.5 6.35417 20.3292 6.0125 19.9875C5.67083 19.6458 5.5 19.2333 5.5 18.75C5.5 18.2667 5.67083 17.8542 6.0125 17.5125C6.35417 17.1708 6.76667 17 7.25 17H17.5C17.3333 17.25 17.2083 17.5208 17.125 17.8125C17.0417 18.1042 17 18.4167 17 18.75C17 19.0667 17.0417 19.375 17.125 19.675C17.2083 19.975 17.325 20.25 17.475 20.5ZM7.19092 6.008L9.19598 13.0154H10.6399L11.9709 8.43391H12.0256L13.3601 13.0154H14.804L16.809 6.008H15.1906L14.0307 10.8872H13.9691L12.6929 6.008H11.3071L10.0274 10.8769H9.96926L8.80934 6.008H7.19092Z'
        fill='inherit'
      />
    </svg>
  );
};

//brukt i truefalse og multiple choice
const CheckIcon: React.FC<SVGProps<SVGSVGElement>> = () => {
  return (
    <svg width='24' height='24' viewBox='0 0 24 24' fill='currentColor' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M8.79995 15.1929L4.9535 11.3464L4.59995 10.9929L4.2464 11.3464L2.8464 12.7464L2.49284 13.1L2.8464 13.4535L8.4464 19.0535L8.79995 19.4071L9.1535 19.0535L21.1535 7.05354L21.5071 6.69999L21.1535 6.34643L19.7535 4.94643L19.4 4.59288L19.0464 4.94643L8.79995 15.1929Z'
        fill='inherit'
        stroke='inherit'
      />
    </svg>
  );
};

//brukt i truefalse
const FalseIcon: React.FC<SVGProps<SVGSVGElement>> = () => {
  return (
    <svg width='24' height='24' viewBox='0 0 24 24' fill='currentColor' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M19.3536 6.76355L19.7071 6.41L19.3536 6.05645L17.9436 4.64645L17.59 4.29289L17.2364 4.64645L12 9.88289L6.76355 4.64645L6.41 4.29289L6.05645 4.64645L4.64645 6.05645L4.29289 6.41L4.64645 6.76355L9.88289 12L4.64645 17.2364L4.29289 17.59L4.64645 17.9436L6.05645 19.3536L6.41 19.7071L6.76355 19.3536L12 14.1171L17.2364 19.3536L17.59 19.7071L17.9436 19.3536L19.3536 17.9436L19.7071 17.59L19.3536 17.2364L14.1171 12L19.3536 6.76355Z'
        fill='inherit'
        stroke='inherit'
      />
    </svg>
  );
};

//brukt i CoursePageItem
const PersonPinCircleDashed: React.FC<SVGProps<SVGSVGElement>> = (props) => {
  console.log(props.fontSize);
  let size = '24';

  if (props.fontSize) {
    switch (props.fontSize) {
      case 'small':
        size = '20';
        break;
      case 'medim':
        size = '24';
        break;
      case 'large':
        size = '35';
        break;
      default:
        size = '24';
        break;
    }
  }

  return (
    <svg width={size} height={size} viewBox='0 0 24 24' fill='transparent' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M12 15C12.9333 15 13.775 14.7708 14.525 14.3125C15.275 13.8542 15.8666 13.25 16.3 12.5C15.7166 12.0167 15.0583 11.6458 14.325 11.3875C13.5916 11.1292 12.8166 11 12 11C11.1833 11 10.4083 11.1292 9.67495 11.3875C8.94162 11.6458 8.28328 12.0167 7.69995 12.5C8.13328 13.25 8.72495 13.8542 9.47495 14.3125C10.225 14.7708 11.0666 15 12 15ZM12 10C12.55 10 13.0208 9.80417 13.4125 9.4125C13.8041 9.02083 14 8.55 14 8C14 7.45 13.8041 6.97917 13.4125 6.5875C13.0208 6.19583 12.55 6 12 6C11.45 6 10.9791 6.19583 10.5875 6.5875C10.1958 6.97917 9.99995 7.45 9.99995 8C9.99995 8.55 10.1958 9.02083 10.5875 9.4125C10.9791 9.80417 11.45 10 12 10Z'
        fill='currentColor'
      />
      <path d='M12.0371 20.5C0.537283 12.5 5.03732 3 12.0372 3C19.0372 3 23.5374 12.5 12.0371 20.5Z' stroke='currentColor' strokeWidth='2' strokeLinejoin='round' strokeDasharray='4 2' />
    </svg>
  );
};
interface DragIndicatorIconProps extends SVGProps<SVGSVGElement> {
  size?: number;
}
//brukt i EnquiryContentCategorization
const DragIndicatorIcon: React.FC<DragIndicatorIconProps> = ({ size = 24 }: DragIndicatorIconProps) => {
  return (
    <svg width={size} height={size} viewBox='0 0 24 24' fill='currentColor' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M11 18C11 19.1 10.1 20 9 20C7.9 20 7 19.1 7 18C7 16.9 7.9 16 9 16C10.1 16 11 16.9 11 18ZM9 10C7.9 10 7 10.9 7 12C7 13.1 7.9 14 9 14C10.1 14 11 13.1 11 12C11 10.9 10.1 10 9 10ZM9 4C7.9 4 7 4.9 7 6C7 7.1 7.9 8 9 8C10.1 8 11 7.1 11 6C11 4.9 10.1 4 9 4ZM15 8C16.1 8 17 7.1 17 6C17 4.9 16.1 4 15 4C13.9 4 13 4.9 13 6C13 7.1 13.9 8 15 8ZM15 10C13.9 10 13 10.9 13 12C13 13.1 13.9 14 15 14C16.1 14 17 13.1 17 12C17 10.9 16.1 10 15 10ZM15 16C13.9 16 13 16.9 13 18C13 19.1 13.9 20 15 20C16.1 20 17 19.1 17 18C17 16.9 16.1 16 15 16Z'
        fill='inherit'
      />
    </svg>
  );
};

export {
  AccordionIcon,
  AccordionSectionIcon,
  SingleChoiceIcon,
  SingleChoiceAlternativeIcon,
  MultipleChoiceIcon,
  MultipleChoiceAlternativeIcon,
  TrueFalseIcon,
  TrueFalseAlternativeIcon,
  JustifyCenterIcon,
  JustifyStartIcon,
  JustifyEndIcon,
  JustifySpaceBetweenIcon,
  JustifySpaceAroundIcon,
  JustifySpaceEvenlyIcon,
  FullscreenIcon,
  WikiIcon,
  CheckIcon,
  FalseIcon,
  PersonPinCircleDashed,
  DragIndicatorIcon,
};
