import AppBar from '@mui/material/AppBar/AppBar';
import { Theme, useTheme } from '@mui/material/styles';
import React, { useEffect, useRef, useState } from 'react';
import HomeIcon from '@mui/icons-material/Home';
import IconButton from '@mui/material/IconButton/IconButton';
import { useStore } from '../../../store/store';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { observer } from 'mobx-react';
import CourseView from '../../../features/courseDashboard/CoursePages/CoursePageList';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import SubdirectoryArrowLeftIcon from '@mui/icons-material/SubdirectoryArrowLeft';
import AddIcon from '@mui/icons-material/Add';
import Popover from '@mui/material/Popover';
import { Link } from 'react-router-dom';
import { NumberParam, useQueryParam } from 'use-query-params';
import Title from '../../titleAndInput/Title';
import { TTreeItemDataViewModel } from '../../../services/models/CourseViewModels';
import { TPageViewModel } from '../../../services/models/PageViewModel';
import Tooltip from '@mui/material/Tooltip/Tooltip';
import PageStatus from '../../../features/courseEdit/pageStatusIndicator/PageStatus';
import { useSnackbar } from 'notistack';
import Popper from '@mui/material/Popper/Popper';
import Paper from '@mui/material/Paper/Paper';
import Typography from '@mui/material/Typography/Typography';
import TextField from '@mui/material/TextField/TextField';
import Button from '@mui/material/Button/Button';
import { createUseStyles } from 'react-jss';
import PreviewIcon from '@mui/icons-material/Preview';
import ShareIcon from '@mui/icons-material/Share';

interface TopBarProps {
  editMode?: boolean;
}

export default observer(function TopBar({ editMode }: TopBarProps) {
  const { courseStore, pageStore, previewStore } = useStore();
  const { loadCourseViewModel, courseViewModel, newEmptyPage, clearCourseViewModel } = courseStore;
  const { getPage, getCoursePage, openPagePreview } = pageStore;
  const { openCoursePreview, sharePreview } = previewStore;
  const [currentPage, setCurrentPage] = useState<TTreeItemDataViewModel>();
  const [coursePage, setCoursePage] = useState<TPageViewModel>();
  const [nextPage, setNextPage] = useState<number>();
  const [previousPage, setPreviousPage] = useState<number>();
  const [open, setOpen] = useState(false);
  const divRef = useRef<HTMLDivElement>(null);

  const anchorRef = React.useRef<HTMLDivElement>(null);
  const [popperOpen, setPopperOpen] = React.useState(false);
  const [popperAnchorEl, setPopperAnchorEl] = React.useState<null | HTMLElement>(null);
  const [newName, setNewName] = useState('');

  const [courseIDParam] = useQueryParam('courseID', NumberParam);
  const [pageIDParam, setPageIDParam] = useQueryParam('pageID', NumberParam);
  const [pageStateParam, setPageStateParam] = useQueryParam('pageState', NumberParam);

  const { enqueueSnackbar } = useSnackbar();

  const handleClick = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const id = open ? 'pageViewPopover' : undefined;

  const theme = useTheme();

  const useStyles = createUseStyles((theme: Theme) => ({
    topBar: {
      flex: '0 1 auto',
      zIndex: theme.zIndex.drawer + 1,
      color: theme.palette.bgOne.contrastText,
      background: theme.palette.bgOne.main,
      padding: `${theme.spacing(1)} ${theme.spacing(3)}`,
      height: 50,
    },
    paperTest: {
      minWidth: 500,
      borderRadius: 0,
      background: theme.palette.bgOne.main,
      color: theme.palette.bgOne.contrastText,
    },
    title: {
      fontSize: 20,
      fontWeight: 500,
      marginLeft: theme.spacing(2),
    },
    tooltip: {
      fontSize: '0.9em',
    },
  }));

  const classes = useStyles({ theme });

  const changePage = (pageID?: number) => {
    if (pageID == undefined) return;
    if (pageStateParam) setPageStateParam(undefined);
    setPageIDParam(pageID);
  };

  const loadPage = async () => {
    if (courseIDParam && pageStateParam) {
      await getPage(courseIDParam, 0, pageStateParam);
    } else if (courseIDParam && pageIDParam) {
      await getPage(courseIDParam, pageIDParam);
    }

    if (courseIDParam && courseViewModel?.courseID !== courseIDParam) {
      await loadCourseViewModel(courseIDParam);
    }

    const page = getCoursePage();
    if (page) {
      setCoursePage(page);
    }
  };

  const createLoadNextPage = async () => {
    if (courseViewModel !== undefined) {
      handleClosePopper();
      const page = await newEmptyPage(Object.keys(courseViewModel.pages.items).length, undefined, newName.length > 0 ? newName : undefined);
      setNewName('');
      if (page !== undefined) {
        changePage(page.coursePageID);
      }
    }
  };

  const handleOnClickNewPage = (e: any) => {
    if (e.keyCode === 13) {
      createLoadNextPage();
    }
  };

  const popperHandleClick = () => {
    setPopperAnchorEl(anchorRef.current);
    setPopperOpen(true);
  };

  const handleClosePopper = () => {
    setPopperOpen(false);
  };

  const emptyFunc = () => {
    return;
  };

  useEffect(() => {
    if (coursePage !== undefined) {
      if (courseViewModel) {
        if (courseViewModel.pages.items[coursePage.coursePageID].data !== undefined) {
          setCurrentPage(courseViewModel.pages.items[coursePage.coursePageID].data);
        }
      }
      if (coursePage.nextPage) {
        setNextPage(coursePage.nextPage.coursePageID);
      } else {
        setNextPage(undefined);
      }
      if (coursePage.prevPage) {
        setPreviousPage(coursePage.prevPage.coursePageID);
      } else {
        setPreviousPage(undefined);
      }
    }

    const handlePageUpDown = (e: any) => {
      if (e.keyCode == 33) {
        //Previous page
        if (coursePage?.prevPage) {
          changePage(coursePage.prevPage.coursePageID);
        } else {
          enqueueSnackbar('You are on the first page', { variant: 'info' });
        }
      } else if (e.keyCode == 34) {
        //Next page
        if (coursePage?.nextPage) {
          changePage(coursePage.nextPage.coursePageID);
        } else {
          enqueueSnackbar('You are on the last page', { variant: 'info' });
        }
      }
    };

    window.addEventListener('keydown', handlePageUpDown, { passive: false });
    return () => {
      window.removeEventListener('keydown', handlePageUpDown);
    };
  }, [coursePage]);

  useEffect(() => {
    loadPage();
  }, [courseIDParam, pageIDParam, pageStateParam]);

  return (
    <>
      <AppBar position='static' className={classes.topBar}>
        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between', height: '100%' }}>
          <IconButton size='small' component={Link} to={'/'} style={{ color: 'inherit' }} onClick={() => clearCourseViewModel()}>
            <HomeIcon />
          </IconButton>
          {editMode && (
            <>
              <IconButton size='small' component={Link} to={`/course?id=${courseIDParam}`} style={{ color: 'inherit' }}>
                <SubdirectoryArrowLeftIcon style={{ transform: 'rotate(90deg)' }} />
              </IconButton>

              <div style={{ display: 'flex', flexGrow: 1, justifyContent: 'center', marginRight: 24 }}>
                <div style={{ minWidth: 35, alignSelf: 'end' }}>
                  <IconButton
                    disabled={previousPage === undefined}
                    size='small'
                    style={{ color: previousPage === undefined ? theme.palette.darkGray.main : 'inherit' }}
                    onClick={() => changePage(previousPage)}
                  >
                    <ArrowBackIosNewIcon />
                  </IconButton>
                </div>

                <div ref={divRef} style={{ position: 'relative', display: 'flex', justifyContent: 'center', alignItems: 'center', width: 500, marginLeft: 20, marginRight: 20 }}>
                  <div style={{ display: 'flex', alignItems: 'center' }} aria-describedby={id}>
                    {currentPage !== undefined && (
                      <>
                        <PageStatus />
                        <div style={{ width: '400px', display: 'flex', justifyContent: 'center' }}>
                          <Title data={currentPage} className={classes.title} topBar />
                        </div>
                        {/*                     <Typography sx={{ color: 'inherit' }} variant='h6' component='div' noWrap>
                      {pageTitle}
                    </Typography> */}
                        {open ? (
                          <IconButton size='small' style={{ color: 'inherit' }} onClick={handleClick}>
                            <KeyboardArrowUpIcon />
                          </IconButton>
                        ) : (
                          <IconButton size='small' style={{ color: 'inherit' }} onClick={handleClick}>
                            <KeyboardArrowDownIcon />
                          </IconButton>
                        )}
                      </>
                    )}
                  </div>
                  <Popover
                    id={id}
                    open={open}
                    anchorEl={divRef.current}
                    onClose={handleClose}
                    PaperProps={{ className: classes.paperTest }}
                    elevation={0}
                    anchorOrigin={{
                      vertical: 'bottom',
                      horizontal: 'center',
                    }}
                    transformOrigin={{
                      vertical: 'top',
                      horizontal: 'center',
                    }}
                  >
                    {courseViewModel && (
                      <CourseView
                        pageID={pageIDParam ? pageIDParam : 0}
                        pages={courseViewModel.pages}
                        height={500}
                        compact
                        handleClose={handleClose}
                        handleNewPageItem={emptyFunc}
                        handleDuplicatePageItem={emptyFunc}
                        handlePastePage={emptyFunc}
                      />
                    )}
                  </Popover>
                </div>

                <div ref={anchorRef} style={{ minWidth: 35 }}>
                  {nextPage !== undefined ? (
                    <IconButton size='small' style={{ color: 'inherit' }} onClick={() => changePage(nextPage)}>
                      <ArrowForwardIosIcon />
                    </IconButton>
                  ) : (
                    <Tooltip title='Create New Page' classes={{ tooltip: classes.tooltip }}>
                      <IconButton size='small' style={{ color: 'inherit' }} onClick={popperHandleClick}>
                        <AddIcon />
                      </IconButton>
                    </Tooltip>
                  )}
                </div>
              </div>
            </>
          )}
          <div>
            <Tooltip title={editMode ? 'Preview Page' : 'Preview Course'} classes={{ tooltip: classes.tooltip }}>
              <IconButton size='small' style={{ color: 'inherit' }} onClick={editMode ? openPagePreview : openCoursePreview}>
                <PreviewIcon />
              </IconButton>
            </Tooltip>
            <Tooltip title={'Get shareable preview link'} classes={{ tooltip: classes.tooltip }}>
              <IconButton size='small' style={{ color: 'inherit', position: 'relative' }} onClick={sharePreview}>
                <ShareIcon />
              </IconButton>
            </Tooltip>
          </div>
        </div>
      </AppBar>
      <Popper
        open={popperOpen}
        anchorEl={popperAnchorEl}
        placement='top'
        sx={{
          zIndex: 10000,
        }}
      >
        <Paper elevation={5} sx={{ background: theme.palette.bgFour.main, padding: theme.spacing(2) }}>
          <Typography variant='h6'>Page name</Typography>
          <TextField variant={'standard'} placeholder={'New Page'} value={newName} onChange={(e) => setNewName(e.target.value)} fullWidth onKeyUp={(e: any) => handleOnClickNewPage(e)} />
          <div style={{ marginTop: theme.spacing(2), display: 'flex', justifyContent: 'end' }}>
            <Button onClick={handleClosePopper} variant='newSecondaryText'>
              Cancel
            </Button>
            <Button onClick={createLoadNextPage} variant='contained' color='primary'>
              OK
            </Button>
          </div>
        </Paper>
      </Popper>
    </>
  );
});
