import { runInAction } from 'mobx';
import { observer } from 'mobx-react';
import React, { useState } from 'react';
import Accordion from '../../components/accordion/Accordion';
import Modal from '../../components/modal/Modal';
import MediaLibrary from '../mediaLibrary/MediaLibrary';
import { TPageViewModel } from '../../services/models/PageViewModel';
import { IPropertyGroup } from '../../services/models/PropertyViewModel';
import { useStore } from '../../store/store';
import { AddStyle } from '../properties/placeholderProperties/PlaceholderProperties';
import { DynamicAudioField } from '../../components/properties/Properties';
import Divider from '@mui/material/Divider/Divider';

interface IPageSoundPropertyProps {
  audio: IPropertyGroup;
  page?: TPageViewModel;
  accordion?: boolean;
}
export default observer(function PageSoundProperty({ audio, accordion = true }: IPageSoundPropertyProps) {
  const { mediaLibraryStore, propertyStore } = useStore();
  const { setDesignProperty } = propertyStore;
  const { openMediaLibraryFromEditor } = mediaLibraryStore;
  const [isMediaLibraryOpen, setIsMediaLibraryOpen] = useState(false);
  const [defaultOpen, setDefaultOpen] = useState(false);

  const handleSelectAudio = (aSrc: string, aFileName?: string) => {
    setIsMediaLibraryOpen(false);
    runInAction(() => {
      if (aFileName !== undefined) {
        audio.items.name.option.value = aFileName;
        setDesignProperty(audio.items.name, aFileName);
      }
      audio.items.URL.option.value = aSrc;
      setDesignProperty(audio.items.URL, aSrc);
    });
  };

  const handleAddAudio = () => {
    openMediaLibraryFromEditor();
    setIsMediaLibraryOpen(true);
    setDefaultOpen(true);
  };

  interface IWrapperProps {
    wrapper: boolean;
    children: React.ReactNode;
  }
  const Wrapper = ({ wrapper, children }: IWrapperProps) => {
    if (wrapper) {
      return (
        <>
          <Accordion title={'Audio'} isOpen={defaultOpen}>
            {children}
          </Accordion>
        </>
      );
    }
    return <>{children}</>;
  };

  if (audio.items.URL.option.value == '') {
    return (
      <>
        <Divider />
        <AddStyle styleName={'Audio'} addStyle={handleAddAudio} />
        <Modal
          isOpen={isMediaLibraryOpen}
          onClose={() => setIsMediaLibraryOpen(false)}
          title='Media Library'
          content={<MediaLibrary onSelect={handleSelectAudio} acceptedFiletypes={['.mp3', '.wav', '.ogg', '.m4a']} />}
        />
        <Divider />
      </>
    );
  }
  return (
    <>
      <Wrapper wrapper={accordion}>
        <DynamicAudioField name={audio.items.name} url={audio.items.URL} autoPlay={audio.items.autoPlay} loop={audio.items.loop} volume={audio.items.volume} onReplace={handleAddAudio} />
        <Modal
          isOpen={isMediaLibraryOpen}
          onClose={() => setIsMediaLibraryOpen(false)}
          title='Media Library'
          content={<MediaLibrary onSelect={handleSelectAudio} acceptedFiletypes={['.mp3', '.wav', '.ogg']} />}
        />
      </Wrapper>
    </>
  );
});
