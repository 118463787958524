import { useTheme } from '@mui/material/styles';
import { observer } from 'mobx-react';
import React from 'react';
import { useStore } from '../../../store/store';
import BackgroundColorProperty from '../../styleProperties/BackgroundColorProperty';
import BackgroundImageProperty from '../../styleProperties/BackgroundImageProperty';
import PageSoundProperty from '../../styleProperties/PageSoundProperty';
import BackgroundGradientProperty from '../../styleProperties/BackgroundGradientProperty';
import Accordion from '../../../components/accordion/Accordion';
import { DynamicTextFieldStyled } from '../../../components/properties/Properties';
import PropertiesTitle from '../../../components/properties/PropertiesTitle';

export interface IBackgroundStyle {
  backgroundImage?: string;
  backgroundSize?: EBackgroundSize;
  backgroundPosition?: string;
  backgroundColor?: string;
}
export enum EBackgroundSize {
  contain = 'contain',
  cover = 'cover',
}

export default observer(function PageProperties() {
  const theme = useTheme();
  const { pageStore } = useStore();
  const { coursePage, coursePageProperties, selectedPlaceholder } = pageStore;

  if (coursePage !== undefined && coursePageProperties !== undefined && selectedPlaceholder === undefined) {
    return (
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        <PropertiesTitle>Page Properties</PropertiesTitle>

        <Accordion title={coursePageProperties.descriptions.items.note.caption} borders={false} boldTitle={false}>
          <DynamicTextFieldStyled
            textVariant={'body1'}
            property={coursePageProperties.descriptions.items.note}
            multiline
            fullWidth
            rows={5}
            placeholder={'Page Note'}
            style={{ width: '100%' }}
            borderRadius={0}
            backgroundColor={theme.palette.bgZero.main}
          />
        </Accordion>

        <BackgroundColorProperty placeholder={selectedPlaceholder} propertyGroup={coursePageProperties.colorAndDesign} page={coursePage} />

        <BackgroundGradientProperty placeholder={selectedPlaceholder} propertyGroup={coursePageProperties.colorAndDesign} page={coursePage} />

        <BackgroundImageProperty placeholder={selectedPlaceholder} propertyGroup={coursePageProperties.colorAndDesign} page={coursePage} />

        <PageSoundProperty audio={coursePageProperties.sound} />
      </div>
    );
  } else {
    return <></>;
  }
});
