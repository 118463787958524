import { Rnd } from 'react-rnd';
import { TPagePlaceholder } from '../../utils/TPagePlaceholder';
import { IPropertyGroup, IPropertyGroupClassicAccordion, IPropertyGroupImageContent, IPropertyGroupOnlineVideo, IPropertyGroupRequirements } from './PropertyViewModel';
import { IPagePlaceholderScriptExportData, IPagePlaceholderStyleExportData as IPagePlaceholderStyleExportData } from './ExportHTMLModels';

export enum EFetchPageState {
  First = 1,
  Last,
  LastEdited,
}

export interface IContent {
  courseID: number;
  coursePageID: number;
  //pageID: number;
  pagePlaceholderID: number;
  contentID: number;
  innerHTML: string;
  jsonObject: any;
  contentType: EContentType | null;
  readOnly: boolean;
  enquiryID: number;
  enquiryContent: IEnquiryContent;
  isShared: boolean;
  CSS: IContentCss;
  video: IVideoProperties;
  onlineVideo: IVideoProperties;
  image?: IImageProperties;
  classicAccordion?: IClassicAccordionProperties;
  contextMenuOpen?: boolean; //Only local, for testing.
}

export interface IContentCss {
  objectFit?: objectFit;
  objectPosition?: string;
}

export interface IOnlineVideoProperties {
  [key: string]: any;
  autoPlay: boolean;
  controls: boolean;
  loop: boolean;
  startTime: number;
  subtitle: boolean;
}
export interface IVideoProperties {
  [key: string]: boolean | string;
  autoPlay: boolean;
  controls: boolean;
  loop: boolean;
  thumbnail: string;
  altText: string;
}

export interface IImageProperties {
  altText: string;
  showAltText: boolean;
  showFullscreenButton: boolean;
}
export interface IClassicAccordionProperties {
  borderColor: string;
  sectionsColor: string;
  panelsColor: string;
  borderRadius: number;
  sectionsGap: number;
  sectionTitle: string;
  selectedSection: number;
  justifySection: string;
}

export type objectFit = 'fill' | 'contain' | 'cover' | 'none' | 'scale-down';

export interface IPageProperties {
  courseID: number;
  coursePageID: number;
  descriptions: IPropertyGroup;
  settings: IPropertyGroup;
  colorAndDesign: IPropertyGroup;
  sound: IPropertyGroup;
  requirements: IPropertyGroupRequirements;
}
export interface IPlaceholderProperties {
  courseID: number;
  coursePageID: number;
  pageID: number;
  pagePlaceholderID: number;
  anchor: IPropertyGroup;
  colorAndDesign: IPropertyGroup;
  descriptions: IPropertyGroup;
}
export interface IContentProperties {
  courseID: number;
  coursePageID: number;
  pageID: number;
  pagePlaceholderID: number;
  contentID: number;
  CSS: IPropertyGroup;
  onlineVideo: IPropertyGroupOnlineVideo;
  video: IPropertyGroup;
  image: IPropertyGroupImageContent;
  classicAccordion: IPropertyGroupClassicAccordion;
}

export interface ICssStyle {
  [key: string]: string;
  backgroundColor: string;
  backgroundImage: string;
  backgroundPosition: string;
  backgroundRepeat: string;
  backgroundSize: string;
  paddingLeft: string;
  paddingRight: string;
  paddingTop: string;
  paddingBottom: string;
  display: string;
  justifyContent: string;
  alignItems: string;
}

export interface IPlaceholderDescriptions {
  title: string;
  description: string;
}

export interface ICssCaption {
  cssNameID: number;
  name: string;
  caption: string;
}

export interface IEnquiryContent {
  [key: string]: IEnquiryTypes;
  question: IEnquiryTypes;
  alternative: IEnquiryTypes;
  //
  //
  //
  accordion: IEnquiryTypes;
  accordionAlternative: IEnquiryTypes;
}

export interface IPagePlaceholder {
  courseID: number;
  coursePageID: number;
  pagePlaceholderID: number;
  contents: IContent[];
  renderedHTML: string;
  parentElement: string;
  left: number;
  top: number;
  width: number;
  height: number;
  right: number;
  bottom: number;
  isLayout: boolean;
  deleted: boolean;
  pagePlacholderLayoutIndex: number;
  colorAndDesign: ICssStyle;
  descriptions: IPlaceholderDescriptions;
  placeholderChildren: IPagePlaceholder[];
  hasChanged: boolean;
  pagePlaceholder: TPagePlaceholder | undefined;
  resizeDirections: resizeDirections;
  styleSaveData?: IPagePlaceholderStyleExportData; //This is just used for saving style data that is needed for "page/UpdateCoursePageHtml".
  scriptSaveData?: IPagePlaceholderScriptExportData; //This is just used for saving script data that is needed for "page/UpdateCoursePageHtml".
}

export interface resizeDirections {
  left: boolean;
  top: boolean;
  right: boolean;
  bottom: boolean;
}

export interface TPageNavigation {
  courseID: number;
  coursePageID: number;
}

export interface TPageViewModel {
  avatar: string;
  courseID: number;
  coursePageID: number;
  index: number;
  isLayout: boolean;
  enquiryID: number;
  sharedCount: number;
  placeholders: IPagePlaceholder[];
  nextPage: TPageNavigation;
  prevPage: TPageNavigation;
  title: string;
  isSaved: boolean;
  backgroundImage: string;
  backgroundSize: string;
  backgroundPosition: string;
  backgroundColor: string;
  backgroundRepeat: string;
}

export interface IPlaceholderSiblings {
  top: pholderReference[];
  right: pholderReference[];
  bottom: pholderReference[];
  left: pholderReference[];
}

export interface pholderReference {
  siblingPlaceholderID: number;
  reference: Rnd | null;
}

export enum EContentType {
  TextStatic = 1,
  TextDynamic,
  Image,
  Video,
  HTML,
  PDF,
  Excel,
  Word,
  PowerPoint,
  OnLineVideo,
  Audio,
  ClassicAccordion,
  ClassicAccordionSection,
  EmbedContent,
  ImageGallery,
}

//export type IEnquiryAlternativeType = 'singleAlternative' | 'multipleAlternative' | 'essay';
export interface IEnquiryTypes {
  [tmp: string]: IEnquiryAlternativeProperties; //Denne er her bare for å fortelle at alle disse objektene har "key" av string.
  singleAlternative: IEnquiryAlternativeProperties;
  multipleAlternative: IEnquiryAlternativeProperties;
  essay: IEnquiryAlternativeProperties;
  flashCard: IEnquiryAlternativeProperties;
  trueOrFalse: IEnquiryAlternativeProperties;
  categorization: IEnquiryAlternativeProperties;
  fileUpload: IEnquiryAlternativeProperties;
  formula: IEnquiryAlternativeProperties;
  matching: IEnquiryAlternativeProperties;
  sorting: IEnquiryAlternativeProperties;
  fillInTheBlank: IEnquiryAlternativeProperties;
  hotSpot: IEnquiryAlternativeProperties;
  numeric: IEnquiryAlternativeProperties;
  fillInTheWord: IEnquiryAlternativeProperties;
  fillInTheNumber: IEnquiryAlternativeProperties;
  selectWord: IEnquiryAlternativeProperties;
  wordL: IEnquiryAlternativeProperties;
  //
  //
  //
  classicAccordion: IEnquiryAlternativeProperties;
}
export interface IEnquiryQuestionProperties {
  enquiryID: number;
  contentID: number;
  contentGroupName: string;
  response?: boolean;
  correctAnswerScore?: number;
  scoreType?: string;
  wrongAnswerScore?: number;
  timeType?: string;
  totalScore?: number;
  hint?: string;
  minScore?: number;
  controlledProgression?: boolean;
  unansweredScore?: number;
  setScoreByAlternative?: boolean;
  randomOrderAlternative?: boolean;
  limitMaxSelections?: number;
  name?: string;
  checked?: boolean;
  singleLine?: boolean;
  wordWrap?: boolean;
  spellCheck?: boolean;
}

export interface IEnquiryAlternativeProperties {
  alternatives: IContent[];
  enquiryID: number;
  contentID: number;
  elementName: string;
  elementID: string;
  isCorrect: boolean;
  correctAnswer?: boolean;
  singleLine?: boolean;
  wordWrap?: boolean;
  spellCheck?: boolean;
}

export interface ICopyObject {
  source: ICopyData;
  dest: ICopyData;
}

export interface ICopyData {
  courseID: number;
  coursePageID: number;
  pagePlaceholderID: number;
}
