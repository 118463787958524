import { makeAutoObservable, runInAction } from 'mobx';
import agent from '../services/api/agent';
import { store } from './store';
import { IPagePlaceholder } from '../services/models/PageViewModel';

//TODO: Se på å flytte denne og andre lik den som en "sub store" til pagestore. Da kan også pageStore splittes opp.
export interface IMoveEnquiryObj {
  contentID: number;
  fromContentID: number;
  toContentID: number;
}
export default class EnquiryStore {
  //variables

  constructor() {
    makeAutoObservable(this);
  }

  moveEnquiryContent = (placeholder: IPagePlaceholder, enquiryContentID: number, fromContentID: number, targetContentID: number) => {
    agent.Enquiry.moveEnquiryContent({ contentID: enquiryContentID, fromContentID: fromContentID, toContentID: targetContentID })
      .then((newContent) => {
        runInAction(() => {
          const currentPlaceholder = store.pageStore.coursePage?.placeholders.find((x) => x.pagePlaceholderID === placeholder.pagePlaceholderID);
          if (currentPlaceholder) {
            currentPlaceholder.contents = [newContent];
          }
        });
      })
      .catch((e) => {
        console.error('Something went wrong ' + e);
      });
  };
}
