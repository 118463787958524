import { ItemId, TreeItem } from '@atlaskit/tree/dist/types/types';
import AddIcon from '@mui/icons-material/Add';
import Box from '@mui/material/Box/Box';
import IconButton from '@mui/material/IconButton/IconButton';
import useTheme from '@mui/material/styles/useTheme';
import Tooltip from '@mui/material/Tooltip/Tooltip';
import { observer } from 'mobx-react';
import React, { useEffect, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { TTreeItemDataViewModel } from '../../../services/models/CourseViewModels';
import { useStore } from '../../../store/store';
import { coursePageStyles } from './CoursePageStyle';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import Title from '../../../components/titleAndInput/Title';
import Button from '@mui/material/Button/Button';
import SettingsIcon from '@mui/icons-material/Settings';
import ContentPasteIcon from '@mui/icons-material/ContentPaste';
import ButtonGroup from '@mui/material/ButtonGroup/ButtonGroup';
import Menu from '@mui/material/Menu/Menu';
import MenuItem from '@mui/material/MenuItem/MenuItem';
import ListItemIcon from '@mui/material/ListItemIcon/ListItemIcon';
import ListItemText from '@mui/material/ListItemText/ListItemText';
import CopyAllIcon from '@mui/icons-material/CopyAll';
import { getPageStatusColor } from '../../../utils/courseDashboard/getPageStatusColor';
import PersonPinCircleIcon from '@mui/icons-material/PersonPinCircle';
import { PersonPinCircleDashed } from '../../../components/icons/Icons';
import useConfirm from '../../../hooks/useConfirm';

export interface ICoursePageItemProps {
  item: TreeItem;
  handleNewPageItem: (item: TreeItem) => void;
  handleDuplicatePageItem: (item: TreeItem, copyChildren: boolean) => void;
  handlePastePage: (item: TreeItem) => void;
  onExpand: (itemID: ItemId) => void;
  onCollapse: (itemID: ItemId) => void;
  provided: any /* TreeDraggableProvided; */;
  snapshot: any;
  isDragging: boolean;
  image?: boolean;
}

export default observer(function CoursePageItem({
  provided,
  item,
  handleNewPageItem,
  handleDuplicatePageItem,
  handlePastePage,
  onExpand,
  onCollapse,
  snapshot,
  isDragging,
  image = false,
}: ICoursePageItemProps) {
  const { courseStore } = useStore();
  const { courseViewModel, pageProperties, removePage, getPageProperties } = courseStore;
  const [onHover, setOnHover] = useState(false);
  const [imgLoading, setImgLoading] = useState(true);
  const theme = useTheme();
  const classes = coursePageStyles({ theme });
  const data: TTreeItemDataViewModel = item.data;

  const [menuOpen, setMenuOpen] = useState(false);
  const [menuAnchorEl, setMenuAnchorEl] = React.useState<null | HTMLElement>(null);
  const [removePageWarning, setRemovePageWarning] = useState('Are you sure you want to delete this page?');

  const [pageHasLastVisit, setPageHasLastVisit] = useState(false);
  const history = useHistory();
  const [Dialog, confirmDelete] = useConfirm('Delete page', removePageWarning);

  const imageBaseURL = process.env.REACT_APP_ENV_BASEURL;
  let url = '';
  if (data.avatar.value) url = data.avatar.value.includes('https') ? data.avatar.value : 'https://' + imageBaseURL + '.norskinteraktiv.no/niasAPI' + data.avatar.value;

  const goToEdit = () => {
    history.push(`/courseEdit?courseID=${courseViewModel != undefined ? courseViewModel.courseID : 0}&pageID=${item.id}`);
  };

  const handleOpenMenu = (e: any) => {
    e.stopPropagation();
    setMenuAnchorEl(e.target);
    setMenuOpen(true);
  };

  const handleCloseMenu = () => {
    setMenuAnchorEl(null);
    setMenuOpen(false);
  };

  const handleMenuClick = (aCopyChildren: boolean) => {
    handleDuplicatePageItem(item, aCopyChildren);
    handleCloseMenu();
  };

  const handleRemovePage = async (e: any) => {
    e.stopPropagation();
    if (item.hasChildren) {
      // eslint-disable-next-line quotes
      setRemovePageWarning("Are you sure you want to delete this page and it's subpages?");
    }

    const result = await confirmDelete();
    if (result) {
      removePage(item.id.toString());
    }
  };

  const handleOpenPageProperties = () => {
    if (courseViewModel) {
      getPageProperties(courseViewModel.courseID, parseInt(item.id.toString()));
    }
  };

  useEffect(() => {
    const lastPageID = courseViewModel?.lastPageVisited ? courseViewModel?.lastPageVisited : 0;
    const lastPageItem = item.children.find((x) => x == lastPageID);
    if (lastPageItem && !item.isExpanded) {
      setPageHasLastVisit(true);
    }
  }, [courseViewModel]);

  return (
    <>
      <Box
        {...provided.dragHandleProps}
        style={{
          display: 'flex',
          flexDirection: 'column',
          width: snapshot.combineWith !== null ? '90%' : '100%',
          marginLeft: snapshot.combineWith !== null ? '10%' : '0%',
          position: 'relative',
        }}
        onClick={handleOpenPageProperties}
        onDoubleClick={goToEdit}
      >
        <div style={{ position: 'absolute', top: 0, left: -40 }}>
          {item.hasChildren && (
            <>
              {item.isExpanded ? (
                <IconButton size='small' className={classes.iconButtonWhite} onClick={() => onCollapse(item.id)} style={{ transform: 'rotate(0deg)' }}>
                  <ExpandLessIcon />
                </IconButton>
              ) : (
                <IconButton size='small' className={classes.iconButtonWhite} onClick={() => onExpand(item.id)} style={{ transform: 'rotate(180deg)' }}>
                  <ExpandLessIcon />
                </IconButton>
              )}
            </>
          )}
        </div>
        <Box
          display='flex'
          style={{
            justifyContent: 'space-between',
            height: image ? '80px' : '40px',
            backgroundColor: snapshot.isDragging ? theme.palette.bgTwo.main : theme.palette.bgFour.main,
            borderRadius: 5,
            overflow: 'hidden',
            outline: '2px solid',
            outlineColor: pageProperties?.coursePageID == item.id ? theme.palette.primary.main : 'transparent',
          }}
        >
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              width: '70%',
              paddingLeft: 10,
            }}
          >
            {image === true && (
              <>
                <div style={{ minWidth: 110, maxWidth: 110, height: 61, background: theme.palette.bgThree.main, alignSelf: 'center', marginRight: theme.spacing(2) }}>
                  <img style={!imgLoading ? { width: '100%', height: '100%', objectFit: 'cover' } : { display: 'none' }} src={url} onLoad={() => setImgLoading(false)} />
                </div>
              </>
            )}
            <Title className={classes.title} data={data} coursePageID={item.id.toString()} fontSize={image ? 20 : undefined} />
          </div>
          <Box display='flex' alignSelf='flex-end' justifyContent='flex-end' alignItems='center' style={{ minWidth: 190, height: '100%' }}>
            {pageHasLastVisit && (
              <Tooltip title='Last Visited Chapter' placement='top' tabIndex={1} arrow={true} classes={{ tooltip: classes.tooltip }} style={{ cursor: 'default' }}>
                <div onClick={() => onExpand(item.id)} className={classes.iconButton} style={{ display: 'flex', height: '20px', cursor: 'pointer' }}>
                  <PersonPinCircleDashed fontSize={'small'} />
                </div>
              </Tooltip>
            )}
            {courseViewModel?.lastPageVisited == item.id && (
              <Tooltip title='Last Visited Page' placement='top' tabIndex={1} arrow={true} classes={{ tooltip: classes.tooltip }} style={{ cursor: 'default' }}>
                <PersonPinCircleIcon className={classes.iconButton} fontSize='small' />
              </Tooltip>
            )}
            <Tooltip title='Page Properties' placement='top' tabIndex={1} arrow={true} classes={{ tooltip: classes.tooltip }}>
              <IconButton size='small' className={classes.iconButton} onClick={handleOpenPageProperties}>
                <SettingsIcon fontSize='inherit' />
              </IconButton>
            </Tooltip>
            <Tooltip title='Delete page' placement='top' tabIndex={1} arrow={true} classes={{ tooltip: classes.tooltip }}>
              <IconButton size='small' className={classes.iconButton} onClick={handleRemovePage}>
                <DeleteIcon fontSize='inherit' />
              </IconButton>
            </Tooltip>
            <Tooltip title='Duplicate page' placement='top' tabIndex={1} arrow={true} classes={{ tooltip: classes.tooltip }}>
              <IconButton size='small' className={classes.iconButton} onClick={handleOpenMenu}>
                <ContentCopyIcon fontSize='inherit' />
              </IconButton>
            </Tooltip>
            <Menu
              id='basic-menu'
              anchorEl={menuAnchorEl}
              open={menuOpen}
              onClose={handleCloseMenu}
              MenuListProps={{
                'aria-labelledby': 'basic-button',
              }}
            >
              <MenuItem onClick={() => handleMenuClick(false)}>
                <ListItemIcon>
                  <ContentCopyIcon fontSize='small' />
                </ListItemIcon>
                <ListItemText>Copy page</ListItemText>
              </MenuItem>
              <MenuItem onClick={() => handleMenuClick(true)}>
                <ListItemIcon>
                  <CopyAllIcon fontSize='small' />
                </ListItemIcon>
                <ListItemText>Copy page and subpages</ListItemText>
              </MenuItem>
            </Menu>
            <Tooltip title='Edit page' placement='top' tabIndex={1} arrow={true} classes={{ tooltip: classes.tooltip }}>
              <IconButton
                data-pagetour='3'
                size='small'
                className={classes.iconButton}
                component={Link}
                to={`/courseEdit?courseID=${courseViewModel != undefined ? courseViewModel.courseID : 0}&pageID=${item.id}`}
              >
                <EditIcon fontSize='inherit' />
              </IconButton>
            </Tooltip>
            <div style={{ minWidth: 10, maxWidth: 10, height: '100%', background: getPageStatusColor(item) }}></div>
          </Box>
        </Box>

        <div
          onMouseEnter={() => setOnHover(true)}
          onMouseLeave={() => setOnHover(false)}
          className={classes.plusBar}
          style={{ height: 10, display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}
        >
          {onHover && !isDragging && (
            <>
              <div className={classes.plusBarContainer} onClick={() => handleNewPageItem(item)}>
                <div style={{ width: '100%', height: '100%' }}></div>
              </div>

              <ButtonGroup variant='contained' size='small' color='secondary' disableElevation aria-label='outlined primary button group' className={classes.plusBarButtonGroup}>
                <Button onClick={() => handleNewPageItem(item)}>
                  <AddIcon fontSize='small' />
                </Button>
                <Button onClick={() => handlePastePage(item)}>
                  <ContentPasteIcon fontSize='small' />
                </Button>
              </ButtonGroup>

              {/*             <div className={classes.plusBarStyleLeft}>
              <AddIcon fontSize='small' />
            </div>
            <div className={classes.plusBarStyleRight}>
              <ContentPasteIcon fontSize='small' style={{ fontSize: 15 }} />
            </div> */}
            </>
          )}
        </div>
      </Box>

      <Dialog />
    </>
  );
});
