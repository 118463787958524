import IconButton from '@mui/material/IconButton/IconButton';
import useTheme from '@mui/material/styles/useTheme';
import TextField from '@mui/material/TextField/TextField';
import Tooltip from '@mui/material/Tooltip/Tooltip';
import { observer } from 'mobx-react';
import React, { useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { TTreeItemDataViewModel } from '../../../services/models/CourseViewModels';
import { useStore } from '../../../store/store';
import { coursePageStyles } from './CoursePageStyle';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import AddIcon from '@mui/icons-material/Add';
import Skeleton from '@mui/material/Skeleton/Skeleton';
import { ICoursePageItemProps } from './CoursePageItem';
import CustomPopper from '../../../components/customPopper/CustomPopper';
import Paper from '@mui/material/Paper/Paper';
import Typography from '@mui/material/Typography/Typography';
import Button from '@mui/material/Button/Button';
import Box from '@mui/material/Box';
import ContentPasteIcon from '@mui/icons-material/ContentPaste';
import ButtonGroup from '@mui/material/ButtonGroup/ButtonGroup';
import { getPageStatusColor } from '../../../utils/courseDashboard/getPageStatusColor';
import SettingsIcon from '@mui/icons-material/Settings';
import useConfirm from '../../../hooks/useConfirm';

export default observer(function CoursePageGridItem({ handleNewPageItem, handlePastePage, handleDuplicatePageItem, provided, item, snapshot, isDragging }: ICoursePageItemProps) {
  const { courseStore, propertyStore } = useStore();
  const { courseViewModel, pageProperties, removePage, getPageProperties } = courseStore;
  const { setPropertyByValueID } = propertyStore;
  const [editTitleMode, setEditTitleMode] = useState(false);
  const [onHover, setOnHover] = useState(false);
  const [imgLoading, setImgLoading] = useState(true);
  const theme = useTheme();
  const classes = coursePageStyles({ theme });
  const data: TTreeItemDataViewModel = item.data;
  const history = useHistory();
  const [removePageWarning, setRemovePageWarning] = useState('Are you sure you want to delete this page?');
  const [Dialog, confirmDelete] = useConfirm('Delete page', removePageWarning);

  const imageBaseURL = process.env.REACT_APP_ENV_BASEURL;
  let url = '';
  if (data.avatar.value) url = data.avatar.value.includes('https') ? data.avatar.value : 'https://' + imageBaseURL + '.norskinteraktiv.no/niasAPI' + data.avatar.value;

  const goToEdit = () => {
    history.push(`/courseEdit?courseID=${courseViewModel != undefined ? courseViewModel.courseID : 0}&pageID=${item.id}`);
  };

  const handleRemovePage = async (e: any) => {
    e.stopPropagation();
    if (item.hasChildren) {
      setRemovePageWarning('Are you sure you want to delete this page and it\'s subpages?');
    }

    const result = await confirmDelete();
    if (result) {
      removePage(item.id.toString());
    }
  };

  const handleOpenPageProperties = () => {
    if (courseViewModel) {
      getPageProperties(courseViewModel.courseID, parseInt(item.id.toString()));
    }
  };

  const onTitleChange = (newTitle: string) => {
    data.title.value = newTitle;
  };

  const updateTitle = () => {
    setEditTitleMode(false);
    setPropertyByValueID(data.title.propertyValueID, data.title.value);
  };

  return (
    <>
      <div
        {...provided.dragHandleProps}
        onClick={handleOpenPageProperties}
        onDoubleClick={goToEdit}
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          width: 180,
          height: 135,
          backgroundColor: snapshot.isDragging ? theme.palette.bgTwo.main : theme.palette.bgFour.main,
          borderRadius: 10,
          overflow: 'hidden',
          margin: 2,
          outline: '2px solid',
          outlineColor: pageProperties?.coursePageID == item.id ? theme.palette.primary.main : 'transparent',
        }}
      >
        <div
          style={{
            flex: '1 1 auto',
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <div style={{ padding: theme.spacing(1) }}>
            {!editTitleMode ? (
              <Typography variant='body2' className={classes.title} onClick={() => setEditTitleMode(true)} noWrap>
                {data.title.value}
              </Typography>
            ) : (
              <>
                <TextField
                  variant={'standard'}
                  size={'small'}
                  hiddenLabel={true}
                  defaultValue={data.title.value}
                  onChange={(e: any) => onTitleChange(e.target.value)}
                  onBlur={updateTitle}
                  fullWidth
                  autoFocus
                />
              </>
            )}
          </div>
          <div style={{ flex: '1 1 auto', width: 110, height: 61, background: 'white', alignSelf: 'center' }}>
            {imgLoading && <Skeleton animation='wave' variant='rectangular' />}
            <img style={!imgLoading ? { width: '100%', height: '100%', objectFit: 'cover' } : { display: 'none' }} src={url} onLoad={() => setImgLoading(false)} />
          </div>
          <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <Tooltip title='Page Properties' placement='top' tabIndex={1} arrow={true} classes={{ tooltip: classes.tooltip }}>
              <IconButton size='small' className={classes.iconButton} onClick={handleOpenPageProperties}>
                <SettingsIcon fontSize='inherit' />
              </IconButton>
            </Tooltip>
            <Tooltip title='Delete page' placement='top' tabIndex={1} arrow={true} classes={{ tooltip: classes.tooltip }}>
              <IconButton size='small' className={classes.iconButton} onClick={(e: any) => handleRemovePage(e)}>
                <DeleteIcon fontSize='inherit' />
              </IconButton>
            </Tooltip>
            <Tooltip title='Duplicate page' placement='top' tabIndex={1} arrow={true} classes={{ tooltip: classes.tooltip }}>
              <IconButton size='small' className={classes.iconButton} onClick={() => handleDuplicatePageItem(item, false)}>
                <ContentCopyIcon fontSize='inherit' />
              </IconButton>
            </Tooltip>
            <Tooltip title='Edit page' placement='top' tabIndex={1} arrow={true} classes={{ tooltip: classes.tooltip }}>
              <IconButton size='small' className={classes.iconButton} component={Link} to={`/courseEdit?courseID=${courseViewModel != undefined ? courseViewModel.courseID : 0}&pageID=${item.id}`}>
                <EditIcon fontSize='inherit' />
              </IconButton>
            </Tooltip>
          </div>
        </div>
        <div style={{ minWidth: 10, maxWidth: 10, height: '100%', background: getPageStatusColor(item) }}></div>
      </div>
      <div
        onMouseEnter={() => setOnHover(true)}
        onMouseLeave={() => setOnHover(false)}
        style={{ width: item.hasChildren ? 320 : 250, height: 20, display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}
      >
        {onHover && !isDragging && (
          <>
            <div className={classes.plusBarContainer} onClick={() => handleNewPageItem(item)}>
              <div style={{ width: '100%', height: '100%' }}></div>
            </div>

            <ButtonGroup variant='contained' size='small' color='secondary' disableElevation aria-label='outlined primary button group' className={classes.plusBarButtonGroup}>
              <Button onClick={() => handleNewPageItem(item)}>
                <AddIcon fontSize='small' />
              </Button>
              <Button onClick={() => handlePastePage(item)}>
                <ContentPasteIcon fontSize='small' />
              </Button>
            </ButtonGroup>
          </>
        )}
      </div>
      <Dialog />
    </>
  );
});
