import { styled, useTheme } from '@mui/material/styles';
import { observer } from 'mobx-react';
import React, { useState } from 'react';
import AlignHorizontalCenterIcon from '@mui/icons-material/AlignHorizontalCenter';
import AlignHorizontalLeftIcon from '@mui/icons-material/AlignHorizontalLeft';
import AlignHorizontalRightIcon from '@mui/icons-material/AlignHorizontalRight';
import AlignVerticalBottomIcon from '@mui/icons-material/AlignVerticalBottom';
import AlignVerticalCenterIcon from '@mui/icons-material/AlignVerticalCenter';
import AlignVerticalTopIcon from '@mui/icons-material/AlignVerticalTop';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup/ToggleButtonGroup';
import ToggleButton from '@mui/material/ToggleButton/ToggleButton';
import { useStore } from '../../store/store';
import { EContentType, IPagePlaceholder } from '../../services/models/PageViewModel';
import { TPropertyValueView } from '../../services/models/PropertyViewModel';
import { runInAction } from 'mobx';

interface IAlignPropertyProps {
  displayProperty: TPropertyValueView;
  justifyContent: TPropertyValueView;
  alignItems: TPropertyValueView;
  placeholder: IPagePlaceholder;
}
export default observer(function AlignProperty({ displayProperty, justifyContent, alignItems, placeholder }: IAlignPropertyProps) {
  const { pageStore, propertyStore } = useStore();
  const { selectedPlaceholder } = pageStore;
  const { setDesignProperty } = propertyStore;
  const theme = useTheme();
  const [display, setDisplay] = useState(displayProperty.option.value);
  const [horizontalAlign, setHorizontalAlign] = useState(justifyContent.option.value);
  const [verticalAlign, setVerticalAlign] = useState(alignItems.option.value);
  const defaultDisplayValue = selectedPlaceholder?.contents[0]?.enquiryContent?.question?.categorization ? 'flex' : 'grid';

  if (selectedPlaceholder === undefined || selectedPlaceholder.contents.length <= 0) {
    return <></>;
  }

  const rejectedTypes = [EContentType.Image, EContentType.Video, EContentType.ClassicAccordion, EContentType.ClassicAccordionSection, null];
  const content = selectedPlaceholder.contents[0];
  if (content !== undefined && rejectedTypes.includes(content.contentType)) {
    return <></>;
  }

  const handleChangeHorizontal = (event: React.MouseEvent<HTMLElement>, aHorizAlign: string) => {
    const value = aHorizAlign !== null ? aHorizAlign : 'normal';

    if (value === null && verticalAlign === '') {
      handleChangeDisplay('');
    } else {
      handleChangeDisplay(defaultDisplayValue);
    }

    setHorizontalAlign(value);
    setDesignProperty(justifyContent, value);

    runInAction(() => {
      placeholder.colorAndDesign.justifyContent = value;
    });
  };

  const handleChangeVertical = (event: React.MouseEvent<HTMLElement>, aVerticalAlign: string) => {
    const value = aVerticalAlign !== null ? aVerticalAlign : 'normal';

    if (value === null && horizontalAlign === '') {
      handleChangeDisplay('');
    } else {
      handleChangeDisplay(defaultDisplayValue);
    }

    setVerticalAlign(value);
    setDesignProperty(alignItems, value);

    runInAction(() => {
      placeholder.colorAndDesign.alignItems = value;
    });
  };

  const handleChangeDisplay = (aDisplayValue: string) => {
    if (display !== aDisplayValue) {
      setDisplay(aDisplayValue);
      setDesignProperty(displayProperty, aDisplayValue);

      runInAction(() => {
        placeholder.colorAndDesign.display = aDisplayValue;
      });
    }
  };

  const StyledToggleButtonGroup = styled(ToggleButtonGroup)(({ theme }) => ({
    '& .MuiToggleButtonGroup-grouped': {
      border: 0,
      '&.Mui-selected': {
        backgroundColor: theme.palette.bgTwo.main,
      },
      '&:not(:first-of-type)': {
        borderRadius: '0',
      },
      '&:first-of-type': {
        borderRadius: '0',
      },
    },
  }));
  return (
    <>
      <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: theme.spacing(3) }}>
        <StyledToggleButtonGroup color='primary' size='small' aria-label='Small sizes' value={horizontalAlign} onChange={handleChangeHorizontal} exclusive fullWidth>
          <ToggleButton value='start' key='start'>
            <AlignHorizontalLeftIcon />
          </ToggleButton>
          <ToggleButton value='center' key='center'>
            <AlignHorizontalCenterIcon />
          </ToggleButton>
          <ToggleButton value='end' key='end'>
            <AlignHorizontalRightIcon />
          </ToggleButton>
        </StyledToggleButtonGroup>
        <StyledToggleButtonGroup color='primary' size='small' aria-label='Small sizes' value={verticalAlign} onChange={handleChangeVertical} exclusive fullWidth>
          <ToggleButton value='start' key='start'>
            <AlignVerticalTopIcon />
          </ToggleButton>
          <ToggleButton value='center' key='center'>
            <AlignVerticalCenterIcon />
          </ToggleButton>
          <ToggleButton value='end' key='end'>
            <AlignVerticalBottomIcon />
          </ToggleButton>
        </StyledToggleButtonGroup>
      </div>
    </>
  );
});
