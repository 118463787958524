import axios, { AxiosResponse } from 'axios';
import { ICourseExportPackages, INewPageModel, TCourseViewModel } from '../models/CourseViewModels';
import { EOwnerCourseListSortByType, ICopyPageObject, TOwnerCourseViewModel } from '../models/OwnerViewModel';
import {
  EContentType,
  IContent,
  IContentProperties,
  ICopyObject,
  ICssCaption,
  IEnquiryAlternativeProperties,
  IPagePlaceholder,
  IPageProperties,
  IPlaceholderProperties,
  TPageViewModel,
} from '../models/PageViewModel';
import { EnquiryContentProperties, EnquiryProperties, ICourseProperties, IPropertyValueObject, IValueMapID } from '../models/PropertyViewModel';
import { ICourseWizard, IWizard /*, TWizard*/ } from '../models/WizardViewModel';
import SnackbarUtils from '../../components/snackbarUtils/SnackbarUtilsConfigurator';
import { ILocalPathModel, IUploadFileResponseModel, LSResponseModel } from '../models/MediaLibraryModels';
import { IMetadata, IUserPasswordChangeResponse, IUserProfileViewModel } from '../models/UserProfileViewModel';
import { CreatorUser } from '../../store/userStore';
import { store } from '../../store/store';
import { EPageState } from '../../store/pageStore';
import { ICoursePageExportData } from '../models/ExportHTMLModels';
import { IEnquiryContentObjectProperties } from '../models/enquiry/EnquiryContentObjectModel';
import { IOwnerIdModel, IRoles, ITestOwner } from '../../store/adminStore';
import { IMoveEnquiryObj } from '../../store/enquiryStore';

export let accessToken = '';

export const setAccessToken = (aToken: string) => {
  accessToken = aToken;
};

let isConnectionSnackbar = false;

if (process.env.REACT_APP_ENV_API != undefined) {
  axios.defaults.baseURL = process.env.REACT_APP_ENV_API;
} else {
  axios.defaults.baseURL = 'https://niastest02.norskinteraktiv.no/niasAPI';
}

// Add a request interceptor
axios.interceptors.request.use(
  function (config) {
    config.headers.authorization = `Bearer ${accessToken}`;
    return config;
  },
  function (error) {
    console.log(error);
    return Promise.reject(error);
  },
);

// Add a response interceptor
axios.interceptors.response.use(
  function (response) {
    SnackbarUtils.closeConnectionError();
    isConnectionSnackbar = false;
    return response;
  },
  function (error) {
    return handleError(error);
  },
);

function timeout(ms: number) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}

let connectionCounter = 0;
const maxConnections = 3;
const handleError = async (error: any) => {
  store.mediaLibraryStore.finnishUploadProgress('Error');
  if (error.response) {
    // The request was made and the server responded with a status code
    // that falls out of the range of 2xx
    console.log(error.response.data);
    console.log(error.response.status);
    console.log(error.response.headers);
    SnackbarUtils.error(error.response.data.value);
  } else if (error.request) {
    // The request was made but no response was received
    // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
    // http.ClientRequest in node.js
    store.pageStore.setPageState(EPageState.disconnected);
    if (!isConnectionSnackbar) {
      console.log(error.request);
      SnackbarUtils.connectionError('Unable to connect.');
      isConnectionSnackbar = true;
    }
    console.log(error.request);
    if (connectionCounter == maxConnections) {
      SnackbarUtils.closeConnectionError();
      isConnectionSnackbar = false;
      SnackbarUtils.connectionError('Unable to connect.', false);
      return Promise.reject(error);
    }
    if (connectionCounter <= maxConnections) {
      await timeout(2000);
      connectionCounter += 1;
      store.pageStore.setPageState(EPageState.reconnecting);
      console.log(123);
      return axios.request(error.config);
    }
  } else {
    // Something happened in setting up the request that triggered an Error
    console.log('Error', error.message);
  }
  console.log(error.config);
  return Promise.reject(error);
};

const responseBody = <T>(response: AxiosResponse<T>) => response.data;

const requests = {
  get: <T>(url: string) => axios.get<T>(url).then(responseBody),
  getFile: (url: string) =>
    axios.get(url, { responseType: 'blob' }).then((response) => {
      const blob = new Blob([response.data], { type: 'application/zip' }),
        downloadUrl = window.URL.createObjectURL(blob),
        disposition = response.headers['content-disposition'];
      let filename = 'export.zip';

      if (disposition && disposition.indexOf('attachment') !== -1) {
        const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/,
          matches = filenameRegex.exec(disposition);

        if (matches != null && matches[1]) {
          filename = matches[1].replace(/['"]/g, '');
        }
      }

      const a = document.createElement('a');
      if (typeof a.download === 'undefined') {
        window.location.href = downloadUrl;
      } else {
        a.href = downloadUrl;
        a.download = filename;
        document.body.appendChild(a);
        a.click();
      }
    }),
  post: <T>(url: string) => axios.post<T>(url).then(responseBody),
  postFormData: <T>(
    url: string,
    formData: FormData, //TODO: Dette bør endres slik at istedet for å laste opp ale filene i en upload, så kan man splitte opp og sende en post per fil. Da er det lettere å få upload progress til å fungere.
  ) =>
    axios
      .post<T>(url, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
        onUploadProgress: (progressEvent) => {
          const progress = (progressEvent.loaded / progressEvent.total) * 100;
          store.mediaLibraryStore.setUploadProgress(progress);
        },
        /*         onDownloadProgress: (progressEvent) => {
          const progress = 50 + (progressEvent.loaded / progressEvent.total) * 50;
          console.log(progress);
        }, */
      })
      .then(responseBody),
  postJson: <T>(url: string, body?: any) =>
    axios
      .post<T>(url, body, {
        headers: {
          'Content-Type': 'application/json',
        },
      })
      .then(responseBody),
};

const Admin = {
  GetOwnerID: (auth0User: string) => requests.post<IOwnerIdModel[]>(`/Administrator/GetOwnerID?Auth0User=${auth0User}`),
  SetTestOwner: (auth0User: string, testOwnerID: number) => requests.get(`/Administrator/SetTestOwner?Auth0User=${auth0User}&TestOwnerID=${testOwnerID}`),
  GetTestOwner: () => requests.get<ITestOwner>('/Administrator/GetTestOwner'),
};

const Owner = {
  createCourse: (obj: string) => requests.postJson<TOwnerCourseViewModel[]>('/Owner/CreateCourse', obj),
  copyCourse: (courseID: number) => requests.post<TOwnerCourseViewModel[]>(`/Owner/CopyCourse?CourseID=${courseID}`),
  getCourses: (sortBy: EOwnerCourseListSortByType, searchQuery: string) => requests.get<TOwnerCourseViewModel[]>(`/Owner/GetCourses?SortByType=${sortBy}&SearchString=${searchQuery}`),
  getCourse: (id: number) => requests.get<TOwnerCourseViewModel[]>(`/Owner/GetCourses?CourseID=${id}`),
  getNewCourseWizard: () => requests.get<ICourseWizard>('/Owner/GetNewCourseWizard'),
  getToolBox: () => console.log('not implemented') /* requests.get<TToolBox>('/Owner/GetToolBox') */,
  deleteCourse: (id: number) => requests.post(`/Owner/DeleteCourse?CourseID=${id}`),
};

const Course = {
  getProperties: (id: number) => requests.get<ICourseProperties>(`/Course/GetProperties?CourseID=${id}`),
  getPages: (id: number) => requests.get<TCourseViewModel>(`/Course/GetPages?CourseID=${id}`),
  movePage: (courseID: number, coursePageID: string, parentCoursePageID: string, index?: number) =>
    requests.postJson('/Course/MovePage', JSON.stringify({ courseID: courseID, coursePageID: coursePageID, parentCoursePageID: parentCoursePageID, index: index !== undefined ? index : null })),
  newPage: (newPageRequest: INewPageModel) => requests.postJson<TPageViewModel>('/Course/NewPage', JSON.stringify(newPageRequest)),
  removePage: (courseID: number, coursePageID?: string) => requests.postJson('/Course/RemovePage', JSON.stringify({ courseID: courseID, coursePageID: coursePageID })),
  copyPage: (copyObject: ICopyPageObject) => requests.postJson('/Course/CopyPage', JSON.stringify(copyObject)),
};

const Page = {
  getPage: (courseID: number, coursePageID: number) => requests.get<TPageViewModel>(`/Page/GetPage?CourseID=${courseID}&CoursePageID=${coursePageID}`),
  getProperties: (courseID: number, coursePageID: number) => requests.get<IPageProperties>(`/Page/GetProperties?CourseID=${courseID}&CoursePageID=${coursePageID}`),
  GetPagePlaceholderProperties: (courseID: number, coursePageID: number, pagePlaceholderID: number) =>
    requests.get<IPlaceholderProperties>(`/Page/GetPagePlaceholderProperties?CourseID=${courseID}&CoursePageID=${coursePageID}&PagePlaceholderID=${pagePlaceholderID}`),
  GetPagePlaceholderContentProperties: (courseID: number, coursePageID: number, pagePlaceholderID: number, contentID: number) =>
    requests.get<IContentProperties>(`/Page/GetPagePlaceholderContentProperties?CourseID=${courseID}&CoursePageID=${coursePageID}&PagePlaceholderID=${pagePlaceholderID}&ContentID=${contentID}`),
  getPageFetchState: (courseID: number, fetchPageState: number) => requests.get<TPageViewModel>(`/Page/GetPage?CourseID=${courseID}&FetchPageState=${fetchPageState}`),
  NewPlaceholder: (pageID: number) => requests.post<IPagePlaceholder>(`/Page/NewPlaceholder?PageID=${pageID}`),
  DeletePlaceholder: (pageID: number, pagePlaceholderID: number) => requests.post(`/Page/DeletePlaceholder?PageID=${pageID}&PagePlaceholderID=${pagePlaceholderID}`),
  /*   NewContent: (pageID: number, pagePlaceholderID: number, coursePageContentType: EContentType) =>
    requests.post<IContent>(`/Page/NewContent?PageID=${pageID}&PagePlaceholderID=${pagePlaceholderID}&CoursePageContentType=${coursePageContentType}`), */
  NewContent: (courseID: number, coursePageID: number, pagePlaceholderID: number, coursePageContentType: EContentType, newContentProperties?: INewContentProperties) =>
    requests.postJson<IContent>(
      '/Page/NewContent',
      JSON.stringify({ courseID: courseID, coursePageID: coursePageID, pagePlaceholderID: pagePlaceholderID, coursePageContentType: coursePageContentType, properties: newContentProperties }),
    ),

  DeleteContent: (courseID: number, coursePageID: number, pagePlaceholderID: number, contentID: number) =>
    requests.postJson('/Page/DeletePagePlaceholderContent', JSON.stringify({ courseID: courseID, coursePageID: coursePageID, pagePlaceholderID: pagePlaceholderID, contentID: contentID })),
  updateContent: (content: IContent) => requests.postJson<IContent>('/Page/UpdateContent', JSON.stringify(content)),
  updateContents: (contents: IContent[]) => requests.postJson<IContent>('/Page/UpdateContents', JSON.stringify(contents)),
  updatePlaceholder: (Placeholder: IPagePlaceholder) => requests.postJson<IPagePlaceholder>('/Page/UpdatePlaceholder', JSON.stringify(Placeholder)),
  changePageLayout: (courseID: number, coursePageID: number, newLayoutNo: number) =>
    requests.postJson('/Page/ChangePageLayout', JSON.stringify({ courseID: courseID, coursePageID: coursePageID, newLayoutNo: newLayoutNo })),
  NewAccordion: (courseID: number, coursePageID: number, pagePlaceholderID: number, enquiryContentType: string, newContentProperties?: INewContentProperties) =>
    requests.postJson<IContent>(
      '/Page/NewAccordion',
      JSON.stringify({
        enquiryID: courseID,
        courseID: courseID,
        coursePageID: coursePageID,
        pagePlaceholderID: pagePlaceholderID,
        enquiryContentType: enquiryContentType,
        properties: newContentProperties,
      }),
    ),
  NewAccordionSection: (courseID: number, enquiryID: number, enquiryContentID: number, coursePageID: number, pagePlaceholderID: number, enquiryContentType: string) =>
    requests.postJson<IContent>(
      '/Page/NewAccordionSection',
      JSON.stringify({
        enquiryID: enquiryID,
        courseID: courseID,
        coursePageID: coursePageID,
        pagePlaceholderID: pagePlaceholderID,
        enquiryContentType: enquiryContentType,
        questionEnquiryID: enquiryContentID,
      }),
    ),
  //TODO: enquiryID vil alltid være 1. Men den må fikses etterhvert.
  NewQuestion: (courseID: number, coursePageID: number, pagePlaceholderID: number, enquiryContentType: string, newContentProperties?: INewContentProperties) =>
    requests.postJson<IContent>(
      '/Page/NewQuestion',
      JSON.stringify({
        enquiryID: courseID,
        courseID: courseID,
        coursePageID: coursePageID,
        pagePlaceholderID: pagePlaceholderID,
        enquiryContentType: enquiryContentType,
        properties: newContentProperties,
      }),
    ),
  NewAnswer: (courseID: number, enquiryID: number, enquiryContentID: number, coursePageID: number, pagePlaceholderID: number, enquiryContentType: string) =>
    requests.postJson<IContent>(
      '/Page/NewAnswer',
      JSON.stringify({
        enquiryID: enquiryID,
        courseID: courseID,
        coursePageID: coursePageID,
        pagePlaceholderID: pagePlaceholderID,
        enquiryContentType: enquiryContentType,
        questionEnquiryID: enquiryContentID,
      }),
    ),
  NewDragDrop: (categorizationData: string) => requests.postJson<IContent>('/Page/NewDragDrop', categorizationData),
  GetCSSNames: () => requests.get<ICssCaption[]>('/Page/GetCSSNames'),
  UpdateCoursePageHtml: (exportData: ICoursePageExportData) => requests.postJson('/Page/UpdateCoursePageHtml', JSON.stringify(exportData)),
  CopyContent: (copyObject: ICopyObject) => requests.postJson('/Page/CopyContent', JSON.stringify(copyObject)),
  SetExpanded: (courseID: number, coursePageID: number, isExpanded: boolean) =>
    requests.postJson('page/SetExpanded', JSON.stringify({ courseID: courseID, coursePageID: coursePageID, isExpanded: isExpanded })),
  UploadCoursePageAvatar: (courseID: number, coursePageID: number, file: any) => {
    const formData = new FormData();
    formData.append('formFile', file);
    return axios
      .post<CreatorUser>(`/Page/UploadCoursePageAvatar?CourseID=${courseID}&CoursePageID=${coursePageID}`, formData, {
        headers: { 'Content-type': 'multipart/form-data' },
      })
      .then(responseBody);
  },
};

const Property = {
  setOption: (value: string, propertyValueID?: number, valueMapID?: IValueMapID) =>
    requests.postJson<IPropertyValueObject>('/Property/SetValue', JSON.stringify({ propertyValueID: propertyValueID, value: value, valueMapID: valueMapID })),
  setOptions: (options: IPropertyValueObject[]) => requests.postJson<IPropertyValueObject[]>('/Property/SetValues', JSON.stringify(options)),
  uploadPhoto: (propertyValueID: number, folder: string, file: any) => {
    const formData = new FormData();
    formData.append('UploadFile', file);
    return axios.post(`/Property/UploadFile?PropertyValueID=${propertyValueID}&Folder=${folder}`, formData, {
      headers: { 'Content-type': 'multipart/form-data' },
    });
  },
  UploadFileFromUrl: (propertyValueID: number, folder: string, url: string) =>
    requests.postJson('/Property/UploadFileFromUrl', JSON.stringify({ propertyValueID: propertyValueID, folder: folder, url: url })),
};

const Enquiry = {
  getEnquiryProperties: (enquiryID: number) => requests.get<EnquiryProperties>(`/Enquiry/getEnquiryProperties?EnquiryID=${enquiryID}`),
  getCourseEnquiryProperties: (courseID: number, enquiryID: number) => requests.get(/*NO TYPE YET*/ `/Enquiry/getCourseEnquiryProperties?CourseID=${courseID}&EnquiryID=${enquiryID}`),
  getEnquiryContentProperties: (contentID: number) => requests.get<EnquiryContentProperties>(`/Enquiry/getEnquiryContentProperties?ContentID=${contentID}`),
  getEnquiryContentObject: (contentObject: IEnquiryAlternativeProperties) => requests.postJson<IEnquiryContentObjectProperties>('/Enquiry/GetEnquiryContentObject', JSON.stringify(contentObject)),
  updateEnquiryContentObject: (contentObject: IEnquiryAlternativeProperties) =>
    requests.postJson<IEnquiryContentObjectProperties>('/Enquiry/UpdateEnquiryContentObject', JSON.stringify(contentObject)),
  moveEnquiryContent: (MoveEnquiryObj: IMoveEnquiryObj) => requests.postJson<IContent>('/Enquiry/MoveEnquiryContent', JSON.stringify(MoveEnquiryObj)),
};

const ExportStaticHtml = {
  PreviewPage: (courseID: number, coursePageID: number) => requests.get<string>(`/ExportStaticHtml/PreviewPage?CourseID=${courseID}&CoursePageID=${coursePageID}`),
  ExportToSCORM: (courseID: number, version: string, language: string) => requests.get(`/ExportStaticHtml/ExportToSCORM?CourseID=${courseID}&Version=${version}&Language=${language}`),
  ExportToHTML: (courseID: number, version: string, language: string) => requests.get(`/ExportStaticHtml/ExportToHTML?CourseID=${courseID}&Version=${version}&Language=${language}`),
  GetExportPackageFile: (courseID: number, courseExportID: number) => requests.getFile(`/ExportStaticHtml/GetExportPackageFile?CourseID=${courseID}&CourseExportID=${courseExportID}`),
  GetExportPackages: (courseID: number) => requests.get<ICourseExportPackages[]>(`/ExportStaticHtml/GetExportPackages?CourseID=${courseID}`),
  PurgeExportPackage: (courseID: number, courseExportID: number) => requests.postJson('/ExportStaticHtml/PurgeExportPackage', JSON.stringify({ courseID: courseID, courseExportID: courseExportID })),
};

const Media = {
  createFolder: (path: string) => requests.postJson<LSResponseModel>('/Media/RunFTPCommand', { mkd: { path: path } }),
  getFolders: () => requests.postJson<LSResponseModel>('/Media/RunFTPCommand', { ls: { path: '', pattern: '.', options: '/S' } }),
  getFilesInFolder: (path: string) => requests.postJson<LSResponseModel>('/Media/RunFTPCommand', { dir: { path: path, pattern: '*' } }),
  rename: (oldPath: string, newPath: string, option: string) => requests.postJson<LSResponseModel>('/Media/RunFTPCommand', { ren: { oldPath: oldPath, newPath: newPath, options: option } }),
  move: (oldPath: string, newPath: string) => requests.postJson<LSResponseModel>('/Media/RunFTPCommand', { ren: { oldPath: oldPath, newPath: newPath, options: '/F' } }),
  deleteFolder: (path: string) => requests.postJson<LSResponseModel>('/Media/RunFTPCommand', { del: { paths: [path], options: '/D' } }),
  deleteFile: (path: string) => requests.postJson<LSResponseModel>('/Media/RunFTPCommand', { del: { paths: [path], options: '/F' } }),
  uploadFile: (path: string, file: FormData) => requests.postFormData<IUploadFileResponseModel>(`/Media/UploadFile?folder=${path}`, file),
  uploadFiles: (path: string, files: FormData) => requests.postFormData<IUploadFileResponseModel>(`/Media/UploadFile?folder=${path}`, files),
  getLocalPathFromURL: (url: string) => requests.get<ILocalPathModel>(`/Media/GetLocalPathFromURL?URL=${url}`),
};

const User = {
  getUserProfileView: () => requests.get<IUserProfileViewModel>('/User/GetUserProfileView'),
  changePassword: (email: string) => requests.post<IUserPasswordChangeResponse>(`/User/ChangePassword?Email=${email}`),
  setUserMetadata: (metadata: IMetadata) => requests.postJson('/User/SetUserMetadata', JSON.stringify(metadata)),
  setUserData: (user: CreatorUser) => requests.postJson('/User/SetUserData', JSON.stringify(user)),
  SetUserAvatar: (file: any) => {
    const formData = new FormData();
    formData.append('UploadFile', file);
    return axios
      .post<CreatorUser>('/User/SetUserAvatar', formData, {
        headers: { 'Content-type': 'multipart/form-data' },
      })
      .then(responseBody);
  },
  GetAllUserRoles: () => requests.get<IRoles[]>('/User/GetAllUserRoles'),
};

const agent = {
  Owner,
  Course,
  Page,
  Property,
  Enquiry,
  User,
  Media,
  ExportStaticHtml,
  Admin,
};

export default agent;

export interface INewContentProperties {
  paddingLeft?: string;
  paddingRight?: string;
  paddingTop?: string;
  paddingBottom?: string;
}
