import { useTheme } from '@mui/material/styles';
import { observer } from 'mobx-react';
import React from 'react';
import { useStore } from '../../../store/store';
import IconButton from '@mui/material/IconButton/IconButton';
import PaddingProperty from '../../styleProperties/PaddingProperty';
import BackgroundColorProperty from '../../styleProperties/BackgroundColorProperty';
import Typography from '@mui/material/Typography/Typography';
import AddIcon from '@mui/icons-material/Add';
import BackgroundImageProperty from '../../styleProperties/BackgroundImageProperty';
import { DynamicTextFieldLabel, DynamicTextFieldStyled } from '../../../components/properties/Properties';
import AlignProperty from '../../styleProperties/AlignProperty';
import BackgroundGradientProperty from '../../styleProperties/BackgroundGradientProperty';
import { runInAction } from 'mobx';
import Accordion from '../../../components/accordion/Accordion';
import PropertiesTitle from '../../../components/properties/PropertiesTitle';

export default observer(function PlaceholderProperties() {
  const { pageStore } = useStore();
  const { selectedContent, selectedPlaceholder, placeholderProperties, selectedEnquiryContent } = pageStore;
  const theme = useTheme();

  const handleUpdateTitle = (aTitle: string) => {
    if (selectedPlaceholder !== undefined) {
      runInAction(() => {
        selectedPlaceholder.descriptions.title = aTitle;
      });
    }
  };

  if (selectedPlaceholder === undefined || selectedContent !== undefined || selectedEnquiryContent !== undefined) {
    return <></>;
  }

  return (
    <div style={{ display: 'flex', flexDirection: 'column' }}>
      {placeholderProperties !== undefined && (
        <>
          <PropertiesTitle>Container Properties</PropertiesTitle>
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'space-between',
              marginTop: theme.spacing(2),
              marginBottom: theme.spacing(4),
            }}
          >
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-between',
                marginLeft: theme.spacing(2),
                marginRight: theme.spacing(2),
              }}
            >
              <AlignProperty
                displayProperty={placeholderProperties.colorAndDesign.items.display}
                justifyContent={placeholderProperties.colorAndDesign.items.justifyContent}
                alignItems={placeholderProperties.colorAndDesign.items.alignItems}
                placeholder={selectedPlaceholder}
              />

              <DynamicTextFieldLabel
                label
                textVariant={'body1'}
                property={placeholderProperties.descriptions.items.title}
                placeholder={`Container ${placeholderProperties.pagePlaceholderID}`}
                style={{ width: '100%' }}
                onBlur={handleUpdateTitle}
              />
            </div>
            <br />
            <Accordion title={placeholderProperties.descriptions.items.note.caption} borders={false} boldTitle={false}>
              <DynamicTextFieldStyled
                textVariant={'body1'}
                property={placeholderProperties.descriptions.items.note}
                multiline
                fullWidth
                placeholder={`Container ${placeholderProperties.pagePlaceholderID} note`}
                style={{ width: '100%' }}
                borderRadius={0}
                backgroundColor={theme.palette.bgZero.main}
              />
            </Accordion>
          </div>
          <PaddingProperty
            propertyGroup={placeholderProperties.colorAndDesign}
            paddingLeftProperty={placeholderProperties.colorAndDesign.items.paddingLeft}
            paddingRightProperty={placeholderProperties.colorAndDesign.items.paddingRight}
            paddingTopProperty={placeholderProperties.colorAndDesign.items.paddingTop}
            paddingBottomProperty={placeholderProperties.colorAndDesign.items.paddingBottom}
            styleName={'paddingTop'}
            placeholder={selectedPlaceholder}
          />
          <BackgroundColorProperty placeholder={selectedPlaceholder} propertyGroup={placeholderProperties.colorAndDesign} />
          <BackgroundGradientProperty placeholder={selectedPlaceholder} propertyGroup={placeholderProperties.colorAndDesign} />
          <BackgroundImageProperty placeholder={selectedPlaceholder} propertyGroup={placeholderProperties.colorAndDesign} />
        </>
      )}

      {/*         <Accordion title={'Padding'}>
          <IconButton onClick={() => setIsPaddingLocked(!isPaddingLocked)} style={{ position: 'absolute', top: 0, right: 0 }}>
            {isPaddingLocked ? <LockIcon /> : <LockOpenIcon />}
          </IconButton>
          <div style={{ display: 'grid', gridTemplateRows: 'auto ', gridTemplateColumns: '70px 100px 70px ', gap: theme.spacing(1) }}>
            <div></div>
            <div style={{ display: 'flex', justifyContent: 'center' }}>
              <StylePropertyComponent aKey={'paddingTop'} keyValue={selectedPlaceholder.colorAndDesign.paddingTop} placeholder={selectedPlaceholder} />
            </div>
            <div></div>
            <div style={{ display: 'flex', justifyContent: 'end', alignItems: 'center' }}>
              <StylePropertyComponent aKey={'paddingLeft'} keyValue={selectedPlaceholder.colorAndDesign.paddingLeft} placeholder={selectedPlaceholder} />
            </div>
            <div>
              <div style={{ width: 100, height: 50, background: 'transparent', borderWidth: 2, borderStyle: 'solid', borderColor: theme.palette.border.main }}></div>
            </div>
            <div style={{ display: 'flex', justifyContent: 'start', alignItems: 'center' }}>
              <StylePropertyComponent aKey={'paddingRight'} keyValue={selectedPlaceholder.colorAndDesign.paddingRight} placeholder={selectedPlaceholder} />
            </div>
            <div></div>
            <div style={{ display: 'flex', justifyContent: 'center' }}>
              <StylePropertyComponent aKey={'paddingBottom'} keyValue={selectedPlaceholder.colorAndDesign.paddingBottom} placeholder={selectedPlaceholder} />
            </div>
            <div></div>
          </div>
        </Accordion>
      <StylePropertyComponent aKey={'backgroundColor'} keyValue={selectedPlaceholder.colorAndDesign.backgroundColor} /> */}
    </div>
  );
});

interface IAddStyleProps {
  styleName: string;
  addStyle: () => void;
  tooltip?: string;
}
export function AddStyle({ styleName, addStyle }: IAddStyleProps) {
  const theme = useTheme();
  return (
    <>
      <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', marginLeft: theme.spacing(2), marginTop: 5, marginBottom: 5, marginRight: 8 }}>
        <Typography variant={'body1'} color={theme.palette.text.disabled}>
          {styleName}
        </Typography>
        <IconButton onClick={addStyle} style={{ color: theme.palette.text.disabled }}>
          <AddIcon />
        </IconButton>
      </div>
    </>
  );
}
