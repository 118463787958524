import { Divider, IconButton, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { ICourseProperties, TPropertyValueView } from '../../../services/models/PropertyViewModel';
import StarIcon from '@mui/icons-material/Star';
import {
  IDescriptionProps,
  StaticTextField,
  DynamicImageField,
  DropdownFieldStyled,
  StaticDateField,
  DynamicTextFieldTitle,
  DynamicTextFieldDescription,
  DynamicPercentFieldStyled,
} from '../../../components/properties/Properties';
import { observer } from 'mobx-react';
import { Theme, useTheme } from '@mui/material/styles';
import { createUseStyles } from 'react-jss';
import { useSnackbar } from 'notistack';
import CompletionRequirements from '../../../components/properties/requiermentProperties/CompletionRequirements';
import { useStore } from '../../../store/store';

interface IPropertyGroupProps {
  propertyGroup: ICourseProperties;
}

export default observer(function DrawerProperties({ propertyGroup }: IPropertyGroupProps) {
  const { courseStore } = useStore();
  const { loadCourseViewModel } = courseStore;
  const [editTitleMode, setEditTitleMode] = useState(false);
  const [editDescMode, setEditDescMode] = useState(false);
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    //Forcing rerender when title changes.
  }, [propertyGroup.descriptions.items.title.option.value]);

  const theme = useTheme();
  const useStyles = createUseStyles((theme: Theme) => ({
    background: {
      borderRadius: 12,
      padding: 16,
      background: theme.palette.bgFive.main,
      flex: '1 1 auto',
    },
    descriptionBackground: {
      borderRadius: 6,
      padding: 8,
      background: theme.palette.bgFour.main,
      marginBottom: 20,
    },
  }));
  const classes = useStyles({ theme });

  const descriptionsComponents: Record<string, React.ComponentType<any>> = {
    title: editTitleMode ? DynamicTextFieldTitle : StaticTextField,
    description: editDescMode ? DynamicTextFieldDescription : StaticTextField,
    createdBy: StaticTextField,
    createdDate: StaticDateField,
    modifiedBy: StaticTextField,
    modifiedDate: StaticDateField,
    status: DropdownFieldStyled,
    copyright: StaticTextField,
    inviteTo: StaticTextField,
  };

  const settingsComponents: Record<string, React.ComponentType<any>> = {
    avatar: DynamicImageField,
    enquiryMinPageVisit: DynamicPercentFieldStyled,
    enquiryMinScore: DynamicPercentFieldStyled,
    completionRequirements: CompletionRequirements,
  };

  interface IPropertyProps extends IDescriptionProps {
    propertyComponents: Record<string, React.ComponentType<any>>;
    onBlur?: () => void;
    type: string;
    property: TPropertyValueView;
    input?: React.CSSProperties;
    fullwidth?: boolean;
  }

  const PropertyComponents = ({ propertyComponents, type, property, textVariant, select, naked, multiline, label, input, fullwidth, onBlur: handleOnBlur }: IPropertyProps) => {
    let obj: JSX.Element = <></>;
    if (property == null) return obj;
    const descTypes = Object.keys(propertyComponents);
    if (descTypes.includes(type)) {
      obj = React.createElement(propertyComponents[type], {
        property: property,
        fullwidth: fullwidth,
        key: type,
        textVariant: textVariant,
        select: select,
        naked: naked,
        label: label,
        multiline: multiline,
        input: input,
        onBlur: handleOnBlur,
      });
    } else {
      enqueueSnackbar(`Could not find ${type} component. Contact support!`, {
        variant: 'error',
      });
    }
    return obj;
  };

  const Divide = () => <Divider style={{ marginTop: 20, marginBottom: 20, background: theme.palette.border.main }} />;

  const [favoriteColor, setFavoriteColor] = useState(false);
  return (
    <>
      <div className={classes.background}>
        <PropertyComponents type={'avatar'} propertyComponents={settingsComponents} property={propertyGroup.settings.items.avatar} />
        <div style={{ display: 'flex', alignItems: 'start', marginBottom: 10 }}>
          <IconButton aria-label='edit' size='medium' style={{ color: favoriteColor ? '#FF6600' : '#CCC' }} onClick={() => setFavoriteColor(!favoriteColor)}>
            <StarIcon fontSize='inherit' />
          </IconButton>
          <div style={{ borderBottomStyle: 'solid', borderBottomWidth: editTitleMode ? 0 : 2, borderColor: theme.palette.border.main, width: '100%' }} onClick={() => setEditTitleMode(true)}>
            <PropertyComponents
              type={'title'}
              propertyComponents={descriptionsComponents}
              multiline
              textVariant={'h6'}
              naked
              property={propertyGroup.descriptions.items.title}
              onBlur={() => setEditTitleMode(false)}
            />
          </div>
        </div>
        <div className={classes.descriptionBackground} onClick={() => setEditDescMode(true)}>
          <PropertyComponents
            type={'description'}
            propertyComponents={descriptionsComponents}
            textVariant={'subtitle2'}
            naked
            multiline
            property={propertyGroup.descriptions.items.description}
            onBlur={() => setEditDescMode(false)}
          />
        </div>
        <PropertyComponents type={'createdDate'} propertyComponents={descriptionsComponents} label textVariant={'body2'} property={propertyGroup.descriptions.items.createdDate} />
        <PropertyComponents type={'modifiedDate'} propertyComponents={descriptionsComponents} label textVariant={'body2'} property={propertyGroup.descriptions.items.modifiedDate} />

        <Divide />

        <PropertyComponents type={'status'} propertyComponents={descriptionsComponents} label textVariant={'body2'} property={propertyGroup.descriptions.items.status} />

        <Divide />

        <PropertyComponents type={'createdBy'} propertyComponents={descriptionsComponents} label textVariant={'body2'} property={propertyGroup.descriptions.items.createdBy} />

        {/*         <Divide />

        <div style={{ display: 'flex', alignItems: 'center', gap: 16 }}>
          <PropertyComponents type={'colorThemes'} propertyComponents={colorComponents} label property={propertyGroup.colorAndDesign.items.colorThemes} />
          <Soon />
        </div> */}

        <Divide />

        <div style={{ display: 'flex', flexDirection: 'column', gap: 16 }}>
          <Typography style={{ fontWeight: 700 }}>Course Completion Requirements:</Typography>
          <PropertyComponents
            type={'enquiryMinPageVisit'}
            propertyComponents={settingsComponents}
            label
            fullwidth={false}
            textVariant={'body2'}
            property={propertyGroup.settings.items.enquiryMinPageVisit}
          />
          <PropertyComponents type={'enquiryMinScore'} propertyComponents={settingsComponents} label textVariant={'body2'} property={propertyGroup.settings.items.enquiryMinScore} />
        </div>
        <Divide />
        <PropertyComponents
          type={'completionRequirements'}
          propertyComponents={settingsComponents}
          label
          textVariant={'body2'}
          property={propertyGroup.settings.items.completionRequirements}
          onBlur={() => loadCourseViewModel(propertyGroup.courseID)}
        />
      </div>
    </>
  );
});
