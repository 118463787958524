import React from 'react';
import { createUseStyles } from 'react-jss';
import { Photo } from '../../../services/models/UnsplashModels';
import { useStore } from '../../../store/store';
import { BiggerTooltip } from '../../../components/tooltip/BiggerTooltip';

interface IUnsplashPhotoProps {
  onClose: () => void;
  onSelect: (src: string, altText?: string) => void;
  photo: Photo;
  index: number;
}
export default function UnsplashPhoto({ onClose, onSelect, photo, index }: IUnsplashPhotoProps) {
  const { unsplashStore } = useStore();
  const { TriggerDownload } = unsplashStore;

  const handleSelectImage = () => {
    TriggerDownload(photo.links.download_location);
    onSelect(photo.urls.regular, photo.alt_description);
    onClose();
  };

  const useStyles = createUseStyles({
    ImageContainer: {
      width: '100%',
      height: '100%',
      position: 'relative',
      '&:hover': {
        '& $referralTitle': {
          backgroundColor: 'rgba(0,0,0,0.5)',
          color: 'white',
        },
        '& $userName': {
          color: 'white',
        },
      },
    },
    Image: {
      width: '100%',
      height: '100%',
      cursor: 'pointer',
      objectFit: 'cover',
      objectPosition: '50% 50%',
    },
    userLink: {
      color: 'inherit',
    },
    referralTitle: {
      position: 'absolute',
      boxSizing: 'border-box',
      top: 0,
      left: 0,
      padding: 16,
      transition: 'background-color 300ms, color 300ms',
      backgroundColor: 'transparent',
      color: 'transparent',
      fontSize: 13,
      wordWrap: 'normal',
      width: '100%',
    },
    userName: {
      fontSize: 13,
      transition: 'color 300ms',
      color: 'transparent',
      '&:hover': {
        textDecoration: 'underline',
      },
    },
  });
  const classes = useStyles();

  return (
    <>
      <div key={photo.urls.small + index} className={classes.ImageContainer}>
        <img src={photo.urls.small} className={classes.Image} onClick={handleSelectImage} />
        <div className={classes.referralTitle}>
          <span>Photo by </span>
          <a className={classes.userName} href={photo.user.links.html + '?utm_source=Mentorkit Creator&utm_medium=referral'} target='_blank' rel='noreferrer'>
            {photo.user.name}
          </a>
          <span> on </span>
          <a className={classes.userName} href={'https://unsplash.com/?utm_source=Mentorkit Creator&utm_medium=referral'} target='_blank' rel='noreferrer'>
            Unsplash
          </a>
        </div>
      </div>
    </>
  );
}
