import React, { useEffect, useRef, useState } from 'react';
import { timeout } from '../../utils/timeout';
import Button from '@mui/material/Button/Button';
import IconButton from '@mui/material/IconButton/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { useStore } from '../../store/store';
import { createUseStyles } from 'react-jss';
import { observer } from 'mobx-react';
import WalkthroughStep from './WalkthroughStep';

interface IWalkthroughProps {
  children: React.ReactNode;
}

//TODO: Støtte for å gå mellom routes.
//TODO: Mer av funksjonalitet bør flyttes til walkthroughStore.
export default observer(function NewWalkthrough(props: IWalkthroughProps) {
  const [step, setStep] = useState(0);
  //const [walkthroughSteps, setIsWalkthroughSteps] = useState<IWalkthroughStepModel[]>([]);
  const [arrowClassName, setArrowClassName] = useState<'walkthroughStepNoArrow' | 'walkthroughStepArrowTop' | 'walkthroughStepArrowBottom' | 'walkthroughStepArrowLeft' | 'walkthroughStepArrowRight'>(
    'walkthroughStepNoArrow',
  );
  const { userStore, walkthroughStore } = useStore();
  const { setCreateCourseWalkthrough } = userStore;
  const {
    walkthroughConfig,
    isWalkthroughOpen,
    walkthroughSteps,
    setIsWalkthroughOpen,
    getIsWalkthroughOpen,
    setCurrentStep,
    getCurrentStep,
    walkthroughTrigger,
    getCurrentTemplate,
    getCurrentConfig,
    closeWalkthrough,
  } = walkthroughStore;
  const rMask = useRef<HTMLDivElement>(null);
  const rStepContent = useRef<HTMLDivElement>(null);
  const rHighlightArea = useRef<HTMLDivElement>(null);

  const useStyles = createUseStyles({
    walkthroughStep: {
      backgroundColor: 'rgb(0, 153, 255)',
      //backgroundColor: 'transparent',
      position: 'absolute',
      top: '-500px',
      left: '-200px',
      zIndex: '999999999999999',
      transition: 'top 600ms ease, left 600ms ease',
      color: 'white',
      padding: 16,
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      maxWidth: 400,
      minHeight: 150,
    },
    walkthroughStepArrowTop: {
      '&:after': {
        position: 'absolute',
        top: -17,
        left: 10,
        content: '""',
        width: 0,
        height: 0,
        borderLeft: '13px solid transparent',
        borderRight: '13px solid transparent',
        borderBottom: '17px solid rgb(0, 153, 255)',
      },
    },
    walkthroughStepArrowBottom: {
      '&:after': {
        position: 'absolute',
        top: '100%',
        left: 10,
        content: '""',
        width: 0,
        height: 0,
        borderLeft: '13px solid transparent',
        borderRight: '13px solid transparent',
        borderTop: '17px solid rgb(0, 153, 255)',
      },
    },
    walkthroughStepArrowLeft: {
      '&:after': {
        position: 'absolute',
        top: 17,
        left: -17,
        content: '""',
        width: 0,
        height: 0,
        borderBottom: '13px solid transparent',
        borderTop: '13px solid transparent',
        borderRight: '17px solid rgb(0, 153, 255)',
      },
    },
    walkthroughStepArrowRight: {
      '&:after': {
        position: 'absolute',
        top: 17,
        left: '100%',
        content: '""',
        width: 0,
        height: 0,
        borderBottom: '13px solid transparent',
        borderTop: '13px solid transparent',
        borderLeft: '17px solid rgb(0, 153, 255)',
      },
    },
    walkthroughStepNoArrow: {
      '&:after': {
        position: 'absolute',
        top: 17,
        left: '100%',
        content: '""',
        width: 0,
        height: 0,
      },
    },
    walkthroughMask: {
      display: 'none',
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100vw',
      height: '100vh',
      zIndex: '99999999999999',
      backgroundColor: 'rgba(0,0,0,0.6)',
      transition: 'clip-path 300ms ease',
      pointerEvents: 'all',
    },
    highlightArea: {
      display: 'none',
      position: 'absolute',
      top: 0,
      left: 0,
      zIndex: '99999999999999',
      backgroundColor: 'transparent',
      pointerEvents: 'all',
    },
    skipButton: {
      cursor: 'pointer',
      alignSelf: 'end',
      '&:hover': {
        textDecoration: 'underline',
        fontStyle: 'italic',
      },
    },
  });

  const classes = useStyles();

  const padding = 10;

  const startWalkthrough = async () => {
    if (walkthroughSteps.length < 1) return;
    if (!rMask.current) return;
    if (!rStepContent.current) return;
    if (!rHighlightArea.current) return;
    setIsWalkthroughOpen(true);
    initializeNextStep();
  };

  const initializeNextStep = async () => {
    if (!rMask.current) return;
    if (!rStepContent.current) return;
    if (!rHighlightArea.current) return;

    const cStep = getCurrentStep();
    const walkthroughStep = walkthroughSteps[cStep + 1];
    if (walkthroughStep.delay !== undefined) {
      await timeout(walkthroughStep.delay);
    }

    setCurrentStep(cStep + 1);
    setStep(cStep + 1);

    const highlightArea: HTMLDivElement = document.querySelectorAll(walkthroughStep.selector)[0] as HTMLDivElement;
    if (!highlightArea) return;

    let maskRect: DOMRect | undefined = undefined;
    if (walkthroughStep.maskSelector !== undefined) {
      const maskArea: HTMLDivElement = document.querySelectorAll(walkthroughStep.maskSelector)[0] as HTMLDivElement;
      if (!maskArea) return;
      maskRect = maskArea.getBoundingClientRect();
    }

    if (walkthroughStep.scrollTo) {
      highlightArea.scrollIntoView({ behavior: 'smooth' });
      await timeout(200);
    }

    const config = getCurrentConfig();
    if (!config.enableInteraction) rHighlightArea.current.style.display = 'block';

    const highlightBoundingRect = highlightArea.getBoundingClientRect();
    const contentBoundingRect = rStepContent.current.getBoundingClientRect();

    setMask(highlightBoundingRect, maskRect);

    switch (walkthroughStep.position) {
      case 'left':
        rStepContent.current.style.top = highlightBoundingRect.top + 'px';
        rStepContent.current.style.left = highlightBoundingRect.x - contentBoundingRect.width - 40 + 'px';
        setArrowClassName('walkthroughStepArrowRight');
        break;
      case 'right':
        rStepContent.current.style.top = highlightBoundingRect.top + 'px';
        rStepContent.current.style.left = highlightBoundingRect.x + highlightBoundingRect.width + 40 + 'px';
        setArrowClassName('walkthroughStepArrowLeft');
        break;
      case 'bottom':
        rStepContent.current.style.top = highlightBoundingRect.top + highlightBoundingRect.height + 40 + 'px';
        rStepContent.current.style.left = highlightBoundingRect.x + 'px';
        setArrowClassName('walkthroughStepArrowTop');
        break;
      /*       case 'top':
        break; */

      default:
        rStepContent.current.style.top = highlightBoundingRect.top + highlightBoundingRect.height + 40 + 'px';
        rStepContent.current.style.left = highlightBoundingRect.x + 'px';
        setArrowClassName('walkthroughStepNoArrow');
        break;
    }

    if (walkthroughStep.attachButtons) {
      for (let i = 0; i < walkthroughStep.attachButtons.length; i++) {
        const button: HTMLDivElement = document.querySelectorAll(walkthroughStep.attachButtons[i])[0] as HTMLDivElement;
        if (!button) continue;
        button.addEventListener('click', highlightAreaEvent, { once: true, passive: false });
      }
      return;
    }

    if (walkthroughStep.attach) {
      highlightArea.addEventListener('click', highlightAreaEvent, { once: true, passive: false });
      return;
    }

    if (walkthroughStep.attachFocusOut) {
      highlightArea.addEventListener('focusout', () => highlightAreaEvent(), { once: true, passive: false });
    }

    const keyCode = walkthroughStep.attachKeyCode;
    if (keyCode !== undefined) {
      highlightArea.addEventListener('keydown', (e: any) => highlightAreaKeyPressEvent(e, keyCode, highlightArea), { passive: false });
    }
  };

  const setMask = (highlightRect: DOMRect, maskRect?: DOMRect) => {
    if (!rMask.current) return;
    if (!rHighlightArea.current) return;

    rHighlightArea.current.style.top = highlightRect.y - padding + 'px';
    rHighlightArea.current.style.left = highlightRect.x - padding + 'px';
    rHighlightArea.current.style.height = highlightRect.height + padding * 2 + 'px';
    rHighlightArea.current.style.width = highlightRect.width + padding * 2 + 'px';

    const config = getCurrentConfig();
    if (config.hideMask) return;

    const maskArea = maskRect !== undefined ? maskRect : highlightRect;

    rMask.current.style.display = 'block';
    rMask.current.style.clipPath = `polygon(
          /* points of the outer triangle going anticlockwise */
          0px 0px, 0px 100vh, 100vw 100vh, 100vw 0px, 
          
          /* return to the first point of the outer triangle */
          0px 0px, 
          
          /* points of the inner triangle going clockwise */
          ${maskArea.x - padding}px ${maskArea.y - padding}px,
          ${maskArea.x + maskArea.width + padding}px ${maskArea.y - padding}px,
          ${maskArea.x + maskArea.width + padding}px ${maskArea.height + maskArea.top + padding}px, 
          ${maskArea.x - padding}px ${maskArea.height + maskArea.top + padding}px,
      
          /* return to the first point of the inner triangle */
          ${maskArea.x - padding}px ${maskArea.y - padding}px
        )`;
  };

  const highlightAreaEvent = async () => {
    goToNextStep();
  };

  const highlightAreaKeyPressEvent = async (event: any, keyCode: number, highlightArea: HTMLDivElement) => {
    if (event.keyCode !== keyCode) return;
    goToNextStep();
    highlightArea.removeEventListener('keydown', () => highlightAreaKeyPressEvent);
  };

  const stopWalkthrough = async () => {
    if (!rMask.current) return;
    if (!rStepContent.current) return;
    rMask.current.style.display = 'none';
    rStepContent.current.style.top = '-500px';
    rStepContent.current.style.left = '-200px';
    setIsWalkthroughOpen(false);
    setCurrentStep(-1);
  };

  const handleSkipWalkthrough = () => {
    setCreateCourseWalkthrough(true);
    closeWalkthrough();
    stopWalkthrough();
  };

  const handleOnClickMask = () => {
    if (!walkthroughConfig.closeOnMask) return;
    stopWalkthrough();
  };

  const goToNextStep = () => {
    const walkthroughSteps = getCurrentTemplate();
    const isOpen = getIsWalkthroughOpen();
    if (!isOpen) return;
    if (getCurrentStep() + 1 >= walkthroughSteps.length) {
      stopWalkthrough();
      closeWalkthrough();
      return;
    }
    initializeNextStep();
  };

  useEffect(() => {
    if (walkthroughTrigger) startWalkthrough();
  }, [walkthroughTrigger]);

  return (
    <>
      <div ref={rMask} className={classes.walkthroughMask} onClick={handleOnClickMask}></div>
      <div ref={rHighlightArea} className={classes.highlightArea}></div>
      <div ref={rStepContent} className={[classes.walkthroughStep, classes[arrowClassName]].join(' ')}>
        {isWalkthroughOpen && step >= 0 && (
          <>
            <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: 8 }}>
              <span>
                {step + 1}/{walkthroughSteps.length}
              </span>
              <IconButton style={{ position: 'absolute', right: 6, top: 6 }} onClick={stopWalkthrough}>
                <CloseIcon />
              </IconButton>
            </div>
            <WalkthroughStep title={walkthroughSteps[step].title} body={walkthroughSteps[step].body} source={walkthroughSteps[step].source} />

            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <span className={classes.skipButton} onClick={handleSkipWalkthrough}>
                Don&apos;t show again
              </span>
              {!walkthroughSteps[step].hideButton && (
                <>
                  <Button variant='newSecondary' onClick={goToNextStep}>
                    {step + 1 == walkthroughSteps.length ? 'done' : 'next'}
                  </Button>
                </>
              )}
            </div>
          </>
        )}
      </div>
      {props.children}
    </>
  );
});
