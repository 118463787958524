import React, { useEffect, useRef, useState } from 'react';
import { useStore } from '../../store/store';
import { observer } from 'mobx-react';
import { useTheme } from '@mui/material/styles';
import MediaFile, { getIconName } from './MediaFile';
import MediaFileUpload from './MediaFileUpload';
import MediaLibraryHeader from './MediaLibraryHeader';
import moment from 'moment';
import TextField, { TextFieldProps } from '@mui/material/TextField/TextField';
import MediaLibraryFolderItem from './MediaLibraryFolderItem';
import { IPathObject } from '../../utils/TreeList';
import CircularLoading from '../../components/loading/circularLoading';
import { Button, Tooltip } from '@mui/material';
import { useSnackbar } from 'notistack';
import { MediaLibraryStyle } from './MediaLibraryStyle';
import { acceptedImageTypes } from '../../utils/AcceptedFileTypes';
import { acceptedVideoTypes } from '../../utils/AcceptedFileTypes';
import MediaFileSelectedImage from './MediaFileSelectedImage';
import { MediaType } from '../../store/media/imageEditorStore';

interface IMediaLibraryProps {
  onSelect?: (aSrc: string, aFileName?: string) => void;
  acceptedFiletypes?: string[];
}

//TODO: Fiks design her. Headeren må fikses, og det må legges inn noe som viser hvilket bilde som er markert.
export default observer(function MediaLibrary({ onSelect, acceptedFiletypes }: IMediaLibraryProps) {
  const { mediaLibraryStore, pageStore, imageEditorStore } = useStore();
  const { createFileEmbed } = pageStore;
  const { openImageEditor } = imageEditorStore;
  const {
    getMediaLibrary,
    mediaFolders,
    currentFolderContent,
    getFilesInFolder,
    currentFolderPath,
    selectedFile,
    renameFileFolder,
    libraryLoadingInitial,
    libraryLoading,
    currentFolderExists,
    createNewFolder,
    closeMediaLibrary,
    isMediaLibraryOpenFromEditor,
    uploadProgress,
    uploadProgressVisibility,
    uploadProgressText,
    selectFileByWebPath,
    renameFileTrigger,
  } = mediaLibraryStore;
  const [tileSize, setTileSize] = useState(120);
  const [listView, setListView] = useState(false);
  const [dragCounter, setDragCounter] = useState(0);
  const [selectedFolder, setSelectedFolder] = useState('');
  const [fileName, setFileName] = useState('');
  const [fileDimension, setFileDimension] = useState('');
  const [thumbnail, setThumbnail] = useState('');
  const [list, setList] = React.useState<IPathObject[]>([]);
  const theme = useTheme();
  const { enqueueSnackbar } = useSnackbar();
  const textFieldRef = useRef<HTMLInputElement>(null);

  const handleSelectFile = () => {
    if (selectedFile != undefined) {
      if (onSelect === undefined) {
        createFileEmbed(selectedFile.webPath, selectedFile.extension);
      } else {
        if (acceptedFiletypes !== undefined) {
          if (!acceptedFiletypes.includes(selectedFile.extension.toLocaleLowerCase())) {
            const fileTypes: string[] = [];

            acceptedFiletypes.forEach((ft) => {
              fileTypes.push(ft.replace('.', ' '));
            });

            enqueueSnackbar('Filetype is not supported. Choose another file: ' + fileTypes, { variant: 'info' });
            return;
          }
        }
        onSelect(selectedFile.webPath, selectedFile.name);
      }
      closeMediaLibrary();
    }
  };

  const updateFolderContent = async () => {
    if (selectedFile === undefined) return;
    const webPath = selectedFile.webPath;
    await getFilesInFolder(currentFolderPath);
    selectFileByWebPath(webPath);
  };

  const handleEditImage = () => {
    if (selectedFile != undefined) {
      openImageEditor(selectedFile.webPath, currentFolderPath, undefined, selectedFile.name, updateFolderContent);
    }
  };

  const handleEditVideo = () => {
    if (selectedFile != undefined) {
      openImageEditor(selectedFile.webPath, currentFolderPath, undefined, selectedFile.name, updateFolderContent, MediaType.Video);
    }
  };

  const handleRenameFile = () => {
    if (textFieldRef.current) {
      textFieldRef.current.focus();
      textFieldRef.current.select();
    }
  };

  const handleFileNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFileName(event.target.value);
  };

  const handleDragIn = (e: any) => {
    e.preventDefault();
    e.stopPropagation();
    setDragCounter(dragCounter + 1);
  };
  const handleDragOut = (e: any) => {
    e.preventDefault();
    e.stopPropagation();
    setDragCounter(dragCounter - 1);
  };

  const updateFileName = (e: any) => {
    renameFileFolder(e.target.value, selectedFile, undefined);
  };

  const handleDeleteChild = (child: IPathObject) => {
    const tmpList = list.filter((x) => x.name !== child.name);
    setList(tmpList);
  };

  const handleSubmit = (e: any) => {
    if (e.charCode == 13) {
      e.target.blur();
    }
  };

  const handleCreateNewFolder = async () => {
    if (mediaFolders !== undefined) {
      const newFolder = await createNewFolder(mediaFolders);
      if (newFolder !== undefined) {
        const newList = list.concat(newFolder);
        setList(newList);
        mediaFolders.children = newList;
      }
    }
  };

  const isSelectedFileImage = () => {
    if (selectedFile !== undefined) {
      const imgFiles = acceptedImageTypes;
      if (imgFiles.includes(selectedFile.extension)) {
        return true;
      }
    }
    return false;
  };

  const isSelectedFileVideo = () => {
    if (selectedFile !== undefined) {
      const vidFiles = acceptedVideoTypes;
      if (vidFiles.includes(selectedFile.extension)) {
        return true;
      }
    }
    return false;
  };

  const classes = MediaLibraryStyle({ theme });

  useEffect(() => {
    handleRenameFile();
  }, [renameFileTrigger]);

  useEffect(() => {
    if (mediaFolders != undefined) {
      setList(mediaFolders.children);
      if (mediaFolders.children.length > 0 && currentFolderPath != mediaFolders.children[0].path) {
        getFilesInFolder(mediaFolders.children[0].path);
      }
    }
  }, [mediaFolders]);

  useEffect(() => {
    if (selectedFile != undefined) {
      setFileName(selectedFile.name);

      if (selectedFile.metadata.tmb != undefined) {
        setThumbnail(selectedFile.metadata.tmb);
      } else {
        setThumbnail(`${process.env.PUBLIC_URL}/Icons/${getIconName(selectedFile.extension)}.svg`);
      }
    } else {
      setFileDimension('');
    }
  }, [selectedFile]);

  useEffect(() => {
    if (selectedFolder !== '') {
      getFilesInFolder(selectedFolder);
    }
  }, [selectedFolder]);

  useEffect(() => {
    getMediaLibrary();
  }, []);

  return (
    <>
      <div style={{ flex: '1 1 auto', display: 'flex', flexDirection: 'column', overflow: 'hidden' }} onDragEnter={handleDragIn} onDragLeave={handleDragOut}>
        {libraryLoadingInitial ? (
          <CircularLoading loadingText='Loading media library' />
        ) : (
          <>
            <MediaLibraryHeader tileSize={tileSize} setTileSize={setTileSize} createNewFolder={handleCreateNewFolder} listView={listView} setListView={setListView} />
            <div style={{ flex: '1 1 auto', display: 'flex', minHeight: 0 }}>
              <div
                style={{
                  minWidth: 300,
                  maxWidth: 300,
                  flex: '0 1 auto',
                  background: theme.palette.bgTwo.main,
                  display: 'flex',
                  flexDirection: 'column',
                  overflow: 'auto',
                  boxSizing: 'border-box',
                  padding: theme.spacing(2),
                  position: 'relative',
                }}
              >
                <div
                  onContextMenu={(e: any) => {
                    e.preventDefault();
                  }}
                  style={{ zIndex: 1 }}
                >
                  {list.map((folder, index) => (
                    <MediaLibraryFolderItem key={index} folder={folder} parentDeleteChild={handleDeleteChild} setSelectedFolder={setSelectedFolder} />
                  ))}
                </div>
              </div>
              <div style={{ position: 'relative', flex: '1 1 auto', display: 'flex', flexWrap: 'wrap' }}>
                <div
                  style={{
                    display: 'flex',
                    flex: '1 1 auto',
                    flexWrap: 'wrap',
                    alignContent: 'flex-start',
                    boxSizing: 'border-box',
                    padding: theme.spacing(2),
                    gap: theme.spacing(2),
                    overflow: 'auto',
                    height: '100%',
                  }}
                >
                  {libraryLoading ? (
                    <CircularLoading loadingText='Loading folder files' />
                  ) : (
                    <>
                      {currentFolderContent !== undefined && currentFolderExists && (
                        <>
                          {currentFolderContent.files.map((file, index) => (
                            <MediaFile key={file.name + index} file={file} tileSize={tileSize} select={handleSelectFile} isListView={listView} />
                          ))}

                          <MediaFileUpload tileSize={tileSize} isListView={listView} />
                        </>
                      )}
                    </>
                  )}
                </div>
                <div
                  style={{
                    position: 'absolute',
                    bottom: 0,
                    left: 0,
                    height: 17,
                    width: '100%',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    visibility: uploadProgressVisibility ? 'visible' : 'hidden',
                  }}
                >
                  <div style={{ fontSize: 10, zIndex: 1 }}>{uploadProgressText}</div>
                  <div className={classes.uploadProgressBar} style={{ width: `${uploadProgress}%` }}></div>
                </div>
              </div>
              <div
                style={{
                  flex: '1 1 auto',
                  minWidth: 300,
                  maxWidth: 300,
                  background: theme.palette.bgTwo.main,
                  padding: theme.spacing(2),
                  display: 'flex',
                  flexDirection: 'column',
                  overflow: 'auto',
                }}
              >
                {selectedFile && (
                  <>
                    <MediaFileSelectedImage selectedFile={selectedFile} />
                    <div style={{ display: 'flex', alignItems: 'center', gap: 8 }}>
                      <span>Name: </span>
                      <TextField
                        inputRef={textFieldRef}
                        value={fileName}
                        id='standard-basic'
                        hiddenLabel
                        variant='standard'
                        onChange={handleFileNameChange}
                        onBlur={updateFileName}
                        onKeyPress={handleSubmit}
                      />
                    </div>
                    <p>
                      <span>Created: </span>
                      {moment(selectedFile.createdTime, 'DD.MM.YYYY HH:mm').format('DD.MM.YYYY HH:mm')}
                    </p>
                    <p>
                      <span>Modified: </span>
                      {moment(selectedFile.modifiedTime, 'DD.MM.YYYY HH:mm').format('DD.MM.YYYY HH:mm')}
                    </p>
                    <p>
                      <span>Size: </span>
                      {selectedFile.length}
                    </p>
                    {fileDimension !== '' && (
                      <p>
                        <span>Dimensions: </span>
                        {fileDimension}
                      </p>
                    )}
                    {selectedFile.extension == '.pdf' && (
                      <div
                        style={{
                          display: 'flex',
                          color: theme.palette.common.white,
                          padding: theme.spacing(1),
                          backgroundColor: theme.palette.primary.main,
                          gap: theme.spacing(1),
                          fontSize: 12,
                          marginBottom: theme.spacing(1),
                        }}
                      >
                        <img src={'/img/NoMobile.svg'} alt='No mobile support' draggable={false} style={{ height: '90%' }} />
                        <span>PDF viewer might not be supported on all mobile devices</span>
                      </div>
                    )}
                    <div style={{ flex: '1 1 auto', alignSelf: 'end', display: 'flex', alignItems: 'flex-end', gap: 8 }}>
                      {isMediaLibraryOpenFromEditor && (
                        <Button variant='contained' color='primary' style={{ alignSelf: 'end' }} onClick={handleSelectFile}>
                          Select
                        </Button>
                      )}
                      {isSelectedFileImage() && (
                        <Button variant='newSecondary' style={{ alignSelf: 'end' }} onClick={handleEditImage}>
                          Edit Image
                        </Button>
                      )}
                      {isSelectedFileVideo() && (
                        <Tooltip title={selectedFile.length > 150000000 ? 'Filesize is too large' : ''} placement='top' arrow>
                          <span>
                            <Button
                              variant='newSecondary'
                              style={{
                                alignSelf: 'end',
                                color: selectedFile.length > 150000000 ? theme.palette.gray.main : 'initial',
                              }}
                              onClick={handleEditVideo}
                              disabled={selectedFile.length > 150000000}
                            >
                              Edit Video
                            </Button>
                          </span>
                        </Tooltip>
                      )}
                    </div>
                  </>
                )}
              </div>
            </div>
          </>
        )}
      </div>
    </>
  );
});
