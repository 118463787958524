import React, { useState } from 'react';
import { TPropertyValueView } from '../../../services/models/PropertyViewModel';
import { DynamicTextFieldStyled } from '../Properties';
import Typography from '@mui/material/Typography/Typography';
import MenuItem from '@mui/material/MenuItem/MenuItem';
import { useTheme } from '@mui/material/styles';
import CustomPopper from '../../../components/customPopper/CustomPopper';
import Paper from '@mui/material/Paper/Paper';
import Button from '@mui/material/Button/Button';
import Box from '@mui/material/Box/Box';
import { createPortal } from 'react-dom';
import useConfirm from '../../../hooks/useConfirm';

interface ICompletionRequirementsProps {
  property: TPropertyValueView;
  onBlur?: (s: string) => void;
}
export default function CompletionRequirements({ property, onBlur }: ICompletionRequirementsProps) {
  const theme = useTheme();

  const [Dialog, confirmChange] = useConfirm(
    'Change completion requirements for all pages',
    'You are about to change the completion requirements for all pages in your course. This action will overwrite your custom settings for individual pages. This change cannot be undone',
  );

  const onChangeCheck = (aValue: string): Promise<boolean> => {
    return new Promise((resolve) => {
      if (aValue === 'completionCustom') {
        const result = confirmChange();
        resolve(result);
      } else {
        resolve(true);
      }
    });
  };

  const updateRequirmentColor = (s: string) => {
    if (onBlur !== undefined) {
      onBlur(s);
    }
  };

  return (
    <>
      <div style={{ display: 'flex', flexDirection: 'column', gap: 16 }}>
        <Typography style={{ fontWeight: 700 }}>{property.caption}:</Typography>

        <div style={{ width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
          <DynamicTextFieldStyled onBlur={updateRequirmentColor} property={property} select updateOnChange onChange={onChangeCheck}>
            {property.options?.map((content) => (
              <MenuItem key={content.value} value={content.value} style={{ fontSize: '0.875', display: content.text === 'Custom' ? 'none' : 'block' }}>
                {content.text}
              </MenuItem>
            ))}
          </DynamicTextFieldStyled>
        </div>

        <div style={{ display: 'grid', gridTemplateColumns: '85px auto' }}>
          <Typography style={{ color: '#8ECCFF' }}>Optional</Typography>
          <Typography>No tracking.</Typography>
          <Typography style={{ color: '#FFDC7E' }}>Required</Typography>
          <Typography>
            All content must be completed <span style={{ fontStyle: 'italic' }}>(default)</span>.
          </Typography>
          <Typography style={{ color: '#FF6600' }}>Mandatory</Typography>
          <Typography>
            Same requirements as <span style={{ color: '#FFDC7E' }}>Required</span>, but with locked navigation.
          </Typography>
          {property.option.text === 'Custom' && (
            <>
              <Typography style={{ color: '#B3EC90' }}>Custom</Typography>
              <Typography>Requirements defined by the pages instead of the course.</Typography>
            </>
          )}
        </div>
      </div>
      <Dialog />
    </>
  );
}
