import { grey } from '@mui/material/colors';
import { createTheme, PaletteColorOptions, responsiveFontSizes } from '@mui/material/styles';

declare module '@mui/material/styles' {
  interface Palette {
    slider: Palette['primary'];
    darkSlider: Palette['primary'];
    gray: Palette['primary'];
    darkGray: Palette['primary'];
    border: Palette['primary'];
    bgZero: Palette['primary'];
    bgOne: Palette['primary'];
    bgTwo: Palette['primary'];
    bgThree: Palette['primary'];
    bgFour: Palette['primary'];
    bgFive: Palette['primary'];
    bgSix: Palette['primary'];
    versionNumber: Palette['primary'];
  }
  interface PaletteOptions {
    slider: PaletteOptions['primary'];
    darkSlider: PaletteOptions['primary'];
    gray: PaletteOptions['primary'];
    darkGray: PaletteOptions['primary'];
    border: PaletteOptions['primary'];
    bgZero: PaletteOptions['primary'];
    bgOne: PaletteOptions['primary'];
    bgTwo: PaletteOptions['primary'];
    bgThree: PaletteOptions['primary'];
    bgFour: PaletteOptions['primary'];
    bgFive: PaletteOptions['primary'];
    bgSix: PaletteOptions['primary'];
    versionNumber: PaletteOptions['primary'];
  }
  interface CustomPalette {
    gray: PaletteColorOptions;
  }
  // eslint-disable-next-line @typescript-eslint/no-empty-interface
  interface Palette extends CustomPalette {}
  // eslint-disable-next-line @typescript-eslint/no-empty-interface
  interface PaletteOptions extends CustomPalette {}
}

declare module '@mui/material/Slider' {
  interface SliderPropsColorOverrides {
    slider: true;
    darkSlider: true;
  }
}
declare module '@mui/material/Button' {
  interface ButtonPropsColorOverrides {
    gray: true;
    darkSlider: true;
  }
}
declare module '@mui/material/Button' {
  interface ButtonPropsVariantOverrides {
    newSecondary: true;
    newSecondaryText: true;
    gray: true;
  }
}
declare module '@mui/material/IconButton' {
  interface IconButtonPropsColorOverrides {
    gray: true;
  }
}

const primaryColor = '#FF6600';
const secondaryColor = '#0099FF';
//Bruk denne for å lage bakgrunnsfarge. Det er viktig at alle fire punktene, dark, main, light og contrasttext blir lagt inn.
//https://material.io/resources/color/#!/?view.left=1&view.right=1
const lTheme = createTheme({
  palette: {
    primary: {
      main: primaryColor,
      contrastText: '#000',
    },
    secondary: {
      main: secondaryColor,
      contrastText: '#000',
    },
    slider: {
      main: '#B0B0B0',
      contrastText: '#fff',
    },
    darkSlider: {
      main: '#757575',
      contrastText: '#fff',
    },
    gray: {
      main: '#B0B0B0',
      contrastText: '#fff',
    },
    darkGray: {
      main: '#8d8d8d',
      light: '#212121',
    },
    border: {
      main: '#424242',
    },
    bgZero: {
      main: '#121212',
      contrastText: '#eee',
    },
    bgOne: {
      main: '#181A1B',
      contrastText: '#eee',
    },
    bgTwo: {
      main: '#F5F5F5',
      light: '#ffffff',
      dark: '#c2c2c2',
      contrastText: '#121212',
    },
    bgThree: {
      main: '#E0E0E0',
      contrastText: '#121212',
    },
    bgFour: {
      main: '#FAFAFA',
      light: '#FFFFFF',
      contrastText: '#121212',
    },
    bgFive: {
      main: '#FAFAFA',
      contrastText: '#121212',
    },
    bgSix: {
      main: '#FAFAFA',
    },
    versionNumber: {
      main: '#a3a3a3',
    },

    error: {
      main: '#BA2E3E  ',
    },
    warning: {
      main: '#F5B800  ',
    },
    info: {
      main: '#0099FF ',
    },
    success: {
      main: '#43A047',
    },
    background: {
      default: '#EEEEEE',
      paper: '#FFFFFF',
    },
    mode: 'light',
  },
  shape: {
    borderRadius: 5,
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 900,
      lg: 1200,
      xl: 1536,
    },
  },

  components: {
    MuiButton: {
      variants: [
        {
          props: { variant: 'newSecondary' },
          style: {
            backgroundColor: grey[200],
            color: '#000',
            '&:hover': {
              backgroundColor: '#69c9ff',
              color: '#000',
            },
            '&:active': {
              backgroundColor: '#0099ff',
              color: '#000',
            },
          },
        },
        {
          props: { variant: 'newSecondaryText' },
          style: {
            backgroundColor: 'transparent',
            color: '#fff',
            '&:hover': {
              backgroundColor: '#69c9ffff',
              color: '#000',
            },
            '&:active': {
              backgroundColor: '#0099ff',
              color: '#000',
            },
          },
        },
        {
          props: { variant: 'gray' },
          style: {
            backgroundColor: grey[200],
            color: '#000',
            '&:hover': {
              backgroundColor: grey[400],
              color: '#000',
            },
            '&:active': {
              backgroundColor: grey[600],
              color: '#000',
            },
          },
        },
      ],
    },
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          fontSize: '1em',
        },
      },
    },
  },
});
export const lightTheme = responsiveFontSizes(lTheme);

const dTheme = createTheme({
  palette: {
    primary: {
      main: primaryColor,
      contrastText: '#000',
    },
    secondary: {
      main: secondaryColor,
      contrastText: '#000',
    },
    gray: {
      main: '#B0B0B0',
      contrastText: '#fff',
    },
    slider: {
      main: '#EEE',
      contrastText: '#000',
    },
    darkSlider: {
      main: '#999999',
      contrastText: '#fff',
    },
    darkGray: {
      main: '#818181',
      light: '#EEEEEE',
    },
    border: {
      main: '#424242',
    },
    bgZero: {
      main: '#121212',
      contrastText: '#eee',
    },
    bgOne: {
      main: '#1a1a1a',
      contrastText: '#eee',
    },
    bgTwo: {
      main: '#212121',
      light: '#121212',
      dark: '#000000',
      contrastText: '#eee',
    },
    bgThree: {
      main: '#303030',
      contrastText: '#eee',
    },
    bgFour: {
      main: '#424242',
      light: '#505050', //used as color for selected folders in media library
      contrastText: '#eee',
    },
    bgFive: {
      main: '#282828',
      contrastText: '#eee',
    },
    bgSix: {
      main: '#121212',
    },
    versionNumber: {
      main: '#181A1B',
      contrastText: '#eee',
    },
    error: {
      main: '#BA2E3E  ',
    },
    warning: {
      main: '#F5B800  ',
    },
    info: {
      main: '#0099FF ',
      contrastText: '#000',
    },
    success: {
      main: '#43A047',
    },
    background: {
      default: '#121212',
      paper: '#272727',
      //default: 'red',
      //paper: 'green',
    },
    text: {
      primary: '#eee',
    },
    mode: 'dark',
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 900,
      lg: 1200,
      xl: 1536,
    },
  },
  components: {
    MuiButton: {
      variants: [
        {
          props: { variant: 'newSecondary' },
          style: {
            backgroundColor: grey[200],
            color: '#000',
            '&:hover': {
              backgroundColor: '#69c9ff',
              color: '#000',
            },
            '&:active': {
              backgroundColor: '#0099ff',
              color: '#000',
            },
          },
        },
        {
          props: { variant: 'newSecondaryText' },
          style: {
            backgroundColor: 'transparent',
            color: '#fff',
            '&:hover': {
              backgroundColor: '#69c9ffff',
              color: '#000',
            },
            '&:active': {
              backgroundColor: '#0099ff',
              color: '#000',
            },
          },
        },
      ],
    },
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          fontSize: '1em',
        },
      },
      defaultProps: {
        disableInteractive: true,
      },
    },
  },
});

export const darkTheme = responsiveFontSizes(dTheme);
