import React from 'react';
import useTheme from '@mui/material/styles/useTheme';
import Typography from '@mui/material/Typography/Typography';
import Divider from '@mui/material/Divider/Divider';

interface IPropertiesTitleProps {
  children?: React.ReactNode;
}

export default function PropertiesTitle({ children }: IPropertiesTitleProps) {
  const theme = useTheme();
  return (
    <>
      <div style={{ position: 'sticky', top: 0, backgroundColor: theme.palette.bgTwo.main, zIndex: '99' }}>
        <Typography sx={{ fontWeight: 'Bold', marginLeft: theme.spacing(2), marginTop: theme.spacing(2), marginBottom: theme.spacing(3), marginRight: theme.spacing(2) }}>{children}</Typography>
        <Divider />
      </div>
    </>
  );
}
