import { makeAutoObservable, runInAction } from 'mobx';
import agent from '../services/api/agent';
import { ICourseExportPackages, INewPageModel, TCourseViewModel } from '../services/models/CourseViewModels';
import { ICopyPageObject, TOwnerCourseViewModel } from '../services/models/OwnerViewModel';
import { ICourseProperties, TPropertyValueView } from '../services/models/PropertyViewModel';
import SnackbarUtils from '../components/snackbarUtils/SnackbarUtilsConfigurator';
import { store } from './store';
import { IPageProperties } from '../services/models/PageViewModel';
import { timeout } from '../utils/timeout';

export enum eExportType {
  scorm12 = '1.2',
  html = 'html',
}
export default class CourseStore {
  courseViewModel: TCourseViewModel | undefined = undefined;
  selectedCourseId = 0;
  selectedCourse: TOwnerCourseViewModel | undefined = undefined;
  courseProperties: ICourseProperties | undefined = undefined;
  pageProperties: IPageProperties | undefined = undefined;
  pagePropertiesPageID = 0;
  newPageCoursePageID = '0';
  loading = false;
  loadingInitial = true;
  loadingExtra = false;
  uploading = false;
  isSelectedCourseOpen = false;

  constructor() {
    makeAutoObservable(this);
  }

  loadCourseViewModel = async (courseId: number) => {
    this.courseViewModel = undefined;
    /* await timeout(100); */
    return await agent.Course.getPages(courseId)
      .then((courseViewModel) => {
        try {
          runInAction(async () => {
            this.courseViewModel = courseViewModel;
            await this.getCourseProperties(courseViewModel.courseID);
          });
          this.setLoadingInitial(false);
          return true;
        } catch (error) {
          console.log(error);
          return false;
        }
      })
      .catch(() => {
        return false;
      });
  };

  clearCourseViewModel = () => {
    this.resetNewPageCoursePageID();
    this.courseViewModel = undefined;
    this.pageProperties = undefined;
  };

  getSelectedCourse = async (courseId: number) => {
    const newCourse: TOwnerCourseViewModel[] = await agent.Owner.getCourse(courseId);
    runInAction(() => {
      this.selectedCourse = newCourse[0];
    });
  };

  getCourseProperties = async (courseId: number) => {
    if (this.courseProperties?.courseID === courseId) return;
    const newCourseProperties = await agent.Course.getProperties(courseId);
    try {
      runInAction(() => {
        this.courseProperties = newCourseProperties;
        this.selectedCourseId = courseId;
      });
    } catch (error) {
      console.log(error);
    }
    return newCourseProperties;
  };

  OpenCourseProperties = () => {
    this.isSelectedCourseOpen = true;
  };

  CloseCourseProperties = () => {
    this.isSelectedCourseOpen = false;
  };

  updateCourseProperties = async (courseId: number) => {
    const newCourseProperties = await agent.Course.getProperties(courseId);
    try {
      runInAction(() => {
        this.courseProperties = newCourseProperties;
      });
    } catch (error) {
      console.log(error);
    }
  };

  uploadCoverPhoto = async (propertyValueView: TPropertyValueView, file: Blob) => {
    await agent.Property.uploadPhoto(propertyValueView.propertyValueID, 'Cover Photo', file);
    if (this.selectedCourseId != 0) {
      await this.updateCourseProperties(this.selectedCourseId);
      if (store.ownerStore.courseList === undefined) return;
      for (let i = 0; i < store.ownerStore.courseList.length; i++) {
        if (store.ownerStore.courseList[i].id == this.selectedCourseId && this.courseProperties) {
          store.ownerStore.courseList[i].course.staticInfoGroup.items.avatar.value = this.courseProperties.settings.items.avatar.option.value; //Updates avatar in courselist on ownerstore
        }
      }
    }
  };

  changeCoverPhoto = async (propertyValueView: TPropertyValueView, value: string) => {
    await store.propertyStore.setOption(propertyValueView, value).then((res) => {
      propertyValueView.option.value = res.value;
    });
    if (this.selectedCourseId != 0) {
      await this.updateCourseProperties(this.selectedCourseId);
      if (store.ownerStore.courseList === undefined) return;
      for (let i = 0; i < store.ownerStore.courseList.length; i++) {
        if (store.ownerStore.courseList[i].id == this.selectedCourseId && this.courseProperties) {
          store.ownerStore.courseList[i].course.staticInfoGroup.items.avatar.value = this.courseProperties.settings.items.avatar.option.value; //Updates avatar in courselist on ownerstore
        }
      }
    }
  };

  movePage = (coursePageID: string, parentCoursePageID: string, index?: number) => {
    if (this.courseViewModel) {
      agent.Course.movePage(this.courseViewModel.courseID, coursePageID, parentCoursePageID, index ? index + 1 : 1);
    }
  };

  newPage = async (index: number, parentCoursePageID?: number) => {
    if (this.courseViewModel) {
      const newPageRequest: INewPageModel = { courseID: this.courseViewModel.courseID, parentCoursePageID: parentCoursePageID, index: index, title: 'New Page', templateNo: 0 };
      const newPage = await agent.Course.newPage(newPageRequest);
      this.newPageCoursePageID = newPage.coursePageID.toString();
      //await TripleTemplate(newPage.pageID);
      await this.loadCourseViewModel(this.courseViewModel.courseID);
      return newPage;
    }
  };

  newEmptyPage = async (index: number, parentCoursePageID?: number, title?: string) => {
    if (this.courseViewModel) {
      const newPageRequest: INewPageModel = { courseID: this.courseViewModel.courseID, parentCoursePageID: parentCoursePageID, index: index, title: title ? title : 'New Page', templateNo: null };
      const newPage = await agent.Course.newPage(newPageRequest);
      agent.Page.changePageLayout(newPage.courseID, newPage.coursePageID, 1);
      this.newPageCoursePageID = newPage.coursePageID.toString(); //This sets the newPageCoursePageID so it can be used to rename the page when it is created.
      await this.loadCourseViewModel(this.courseViewModel.courseID);
      return newPage;
    }
  };

  removePage = async (coursePageID: string) => {
    if (this.courseViewModel) {
      await agent.Course.removePage(this.courseViewModel.courseID, coursePageID);
      // SnackbarUtils.success('Course page deleted', () => console.log('undo page delete'), 'undo', 5000);
      SnackbarUtils.success('Course page deleted', undefined, undefined, 5000);
      this.loadCourseViewModel(this.courseViewModel.courseID);

      if (coursePageID === this.pagePropertiesPageID.toString()) {
        this.deselectPageProperties();
      }
    }
  };

  getPageProperties = async (courseId: number, coursePageId: number) => {
    const properties = await agent.Page.getProperties(courseId, coursePageId);

    runInAction(async () => {
      this.pagePropertiesPageID = coursePageId;
      this.pageProperties = properties;
    });
  };

  deselectPageProperties = async () => {
    runInAction(async () => {
      this.pagePropertiesPageID = 0;
      this.pageProperties = undefined;
    });
  };

  getExportPackages = async (aCourseID: number): Promise<ICourseExportPackages[]> => {
    const exportPackages = await agent.ExportStaticHtml.GetExportPackages(aCourseID);

    //sort exportPackages by newest date first
    exportPackages.sort((a, b) => {
      const aDate = new Date(a.date);
      const bDate = new Date(b.date);
      return bDate.getTime() - aDate.getTime();
    });
    return exportPackages;
  };

  getExportPackageFile = (aCourseID: number, aExportID: number) => {
    agent.ExportStaticHtml.GetExportPackageFile(aCourseID, aExportID);
  };

  purgeExportPackage = (aCourseID: number, aExportID: number) => {
    return agent.ExportStaticHtml.PurgeExportPackage(aCourseID, aExportID);
  };

  //language string må være feks nb-no.
  generateExport = async (version: eExportType, language: string) => {
    if (!this.courseViewModel) return;
    const courseID = this.courseViewModel.courseID;

    if (version === eExportType.html) {
      await agent.ExportStaticHtml.ExportToHTML(courseID, '', language);
      return await this.getExportPackages(courseID);
    } else {
      await agent.ExportStaticHtml.ExportToSCORM(courseID, version, language);
      return await this.getExportPackages(courseID);
    }
  };

  copyPage = (aCopyObject: ICopyPageObject): Promise<any> => {
    return agent.Course.copyPage(aCopyObject);
  };

  deselectCourseProperties = () => {
    runInAction(() => {
      this.courseProperties = undefined;
      this.selectedCourseId = 0;
      this.isSelectedCourseOpen = false;
    });
  };

  resetNewPageCoursePageID = () => {
    this.newPageCoursePageID = '0';
  };

  setLoadingInitial = (state: boolean) => {
    this.loadingInitial = state;
  };

  setLoadingExtra = (state: boolean) => {
    this.loadingExtra = state;
  };
}
