import React, { useEffect, useRef, useState } from 'react';
import { EBackgroundSize } from '../properties/pageProperties/PageProperties';
import { useStore } from '../../store/store';
import Typography from '@mui/material/Typography/Typography';
import Modal from '../../components/modal/Modal';
import UnsplashContent from '../video/Unsplash/UnsplashContent';
import UnsplashHeader from '../video/Unsplash/UnsplashHeader';
import Skeleton from '@mui/material/Skeleton/Skeleton';
import { Theme, useTheme } from '@mui/material/styles';
import Menu from '@mui/material/Menu/Menu';
import MenuItem from '@mui/material/MenuItem/MenuItem';
import MediaLibrary from '../mediaLibrary/MediaLibrary';
import IconButton from '@mui/material/IconButton/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import { IPropertyGroupColor, IPropertyValue } from '../../services/models/PropertyViewModel';
import { IPagePlaceholder, TPageViewModel } from '../../services/models/PageViewModel';
import { runInAction } from 'mobx';
import Accordion from '../../components/accordion/Accordion';
import { observer } from 'mobx-react';
import { AddStyle } from '../properties/placeholderProperties/PlaceholderProperties';
import TextField from '@mui/material/TextField/TextField';
import Button from '@mui/material/Button/Button';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup/ToggleButtonGroup';
import ToggleButton from '@mui/material/ToggleButton/ToggleButton';
import PercentIcon from '@mui/icons-material/Percent';
import SettingsOverscanIcon from '@mui/icons-material/SettingsOverscan';
import AspectRatioIcon from '@mui/icons-material/AspectRatio';
import FilterCenterFocusIcon from '@mui/icons-material/FilterCenterFocus';
import AlignHorizontalCenterIcon from '@mui/icons-material/AlignHorizontalCenter';
import AlignHorizontalLeftIcon from '@mui/icons-material/AlignHorizontalLeft';
import AlignHorizontalRightIcon from '@mui/icons-material/AlignHorizontalRight';
import AlignVerticalBottomIcon from '@mui/icons-material/AlignVerticalBottom';
import AlignVerticalCenterIcon from '@mui/icons-material/AlignVerticalCenter';
import AlignVerticalTopIcon from '@mui/icons-material/AlignVerticalTop';
import { clamp } from '../../utils/ClampNumber';
import { createUseStyles } from 'react-jss';
import Divider from '@mui/material/Divider/Divider';
import { acceptedImageTypes } from '../../utils/AcceptedFileTypes';
import EditIcon from '@mui/icons-material/Edit';
import { Tooltip } from '@mui/material';
import { urltoFile } from '../../utils/exportAsImage';
import moment from 'moment';

interface IBackgroundImagePropertyProps {
  propertyGroup: IPropertyGroupColor;
  placeholder?: IPagePlaceholder;
  page?: TPageViewModel;
}
export default observer(function BackgroundImageProperty({ propertyGroup, placeholder, page }: IBackgroundImagePropertyProps) {
  const theme = useTheme();
  const { mediaLibraryStore, propertyStore, imageEditorStore } = useStore();
  const { setDesignProperty, setDesignPropertyTimer, setDesignPropertyList } = propertyStore;
  const { openMediaLibraryFromEditor, uploadImage } = mediaLibraryStore;
  const { openImageEditor } = imageEditorStore;
  const [image, setImage] = useState('');
  const [isUnsplashOpen, setIsUnsplashOpen] = useState(false);
  const [isMediaLibraryOpen, setIsMediaLibraryOpen] = useState(false);
  const [defaultOpen, setDefaultOpen] = useState(false);
  const [imgLoading, setImgLoading] = useState(true);

  const group = { ...propertyGroup.items };

  const [menuAnchorEl, setMenuAnchorEl] = useState(null);
  const menuOpen = Boolean(menuAnchorEl);
  const [bgSize, setBgSize] = useState(group.backgroundSize.option.value.includes('%') ? 'percentage' : group.backgroundSize.option.value);
  const [sizeValue, setSizeValue] = useState(group.backgroundSize.option.value.includes('%') ? 'percentage' : group.backgroundSize.option.value);
  const [percentageValue, setPercentageValue] = useState(group.backgroundSize.option.value.includes('%') ? group.backgroundSize.option.value.replace('%', '') : 100);

  const [focusMode, setFocusMode] = useState(false);
  const [xPos, setXPos] = useState(-100);
  const [yPos, setYPos] = useState(-100);
  const [isMouseDown, setIsMouseDown] = useState(false);
  const [boundingBox, setBoundingBox] = useState<DOMRect | null>(null);
  const imageContainer = useRef<HTMLImageElement>(null);

  const [bgRepeat, setBgRepeat] = React.useState(group.backgroundRepeat.option.value);
  const [bgPos, setBgPos] = React.useState(group.backgroundPosition.option.value);
  const [bgPosHorizontal, setBgPosHorizontal] = React.useState(group.backgroundPosition.option.value.split(' ')[0]?.replace('%', ''));
  const [bgPosVertical, setBgPosVertical] = React.useState(group.backgroundPosition.option.value.split(' ')[1]?.replace('%', ''));

  const selectImage = async (aSrc: string) => {
    const date = new Date();
    const momentString = moment(date).format('DDMMYYHHmm');

    const file = await urltoFile(aSrc, `Unsplash-${momentString}.jpg`, 'image/jpeg');
    uploadImage(file, 'Unsplash').then((res) => {
      if (res.url === '') return;

      setImage(res.url);
      runInAction(() => {
        group.backgroundImage.option.value = 'url(' + res.url + ')';
        setDesignPropertyTimer(group.backgroundImage, 'url(' + res.url + ')');
        if (placeholder !== undefined) {
          placeholder.colorAndDesign.backgroundImage = 'url(' + res.url + ')';
        }
        if (page !== undefined) {
          page.backgroundImage = 'url(' + res.url + ')';
        }
      });
    });

    setIsUnsplashOpen(false);
  };

  const selectImageFromMediaLibrary = (aSrc: string) => {
    setImage(aSrc);
    runInAction(() => {
      group.backgroundImage.option.value = 'url(' + aSrc + ')';
      setDesignPropertyTimer(group.backgroundImage, 'url(' + aSrc + ')');
      if (placeholder !== undefined) {
        placeholder.colorAndDesign.backgroundImage = 'url(' + aSrc + ')';
      }
      if (page !== undefined) {
        page.backgroundImage = 'url(' + aSrc + ')';
      }
    });
    setIsMediaLibraryOpen(false);
  };

  const handleRemoveBackgroundImage = () => {
    runInAction(() => {
      group.backgroundImage.option.value = '';
      group.backgroundPosition.option.value = '';
      group.backgroundSize.option.value = '';
      group.backgroundRepeat.option.value = '';

      const propertyList: IPropertyValue[] = [
        { propertyValueView: group.backgroundImage, value: '' },
        { propertyValueView: group.backgroundPosition, value: '' },
        { propertyValueView: group.backgroundSize, value: '' },
        { propertyValueView: group.backgroundRepeat, value: '' },
      ];
      setDesignPropertyList(propertyGroup, propertyList);

      if (placeholder !== undefined) {
        placeholder.colorAndDesign.backgroundImage = '';
        placeholder.colorAndDesign.backgroundPosition = '';
        placeholder.colorAndDesign.backgroundSize = '';
        placeholder.colorAndDesign.backgroundRepeat = 't';
      }
      if (page !== undefined) {
        page.backgroundImage = '';
        page.backgroundPosition = '';
        page.backgroundSize = '';
        page.backgroundRepeat = '';
      }
    });
    setImage('');
  };

  const handleRemoveBackgroundGradient = async () => {
    await runInAction(() => {
      group.backgroundColor.option.value = '';
      group.backgroundImage.option.value = '';

      const propertyList: IPropertyValue[] = [
        { propertyValueView: group.backgroundColor, value: '' },
        { propertyValueView: group.backgroundImage, value: '' },
      ];
      setDesignPropertyList(propertyGroup, propertyList);

      if (placeholder !== undefined) {
        placeholder.colorAndDesign.backgroundColor = '';
        placeholder.colorAndDesign.backgroundImage = '';
      }
      if (page !== undefined) {
        page.backgroundColor = '';
        page.backgroundImage = '';
      }
    });
  };

  const handleAddBackgroundImage = async () => {
    await handleRemoveBackgroundGradient();
    setImage('');

    setDefaultOpen(true);

    const propertyList: IPropertyValue[] = [
      { propertyValueView: group.backgroundImage, value: 'url()' },
      { propertyValueView: group.backgroundPosition, value: '50% 50%' },
      { propertyValueView: group.backgroundSize, value: 'cover' },
      { propertyValueView: group.backgroundRepeat, value: 'no-repeat' },
    ];
    setDesignPropertyList(propertyGroup, propertyList);

    setBgRepeat('no-repeat');
    setBgPos('50% 50%');
    setBgSize('cover');

    runInAction(() => {
      group.backgroundImage.option.value = 'url()';
      group.backgroundPosition.option.value = '50% 50%';
      group.backgroundSize.option.value = 'cover';
      group.backgroundRepeat.option.value = 'no-repeat';

      if (placeholder !== undefined) {
        placeholder.colorAndDesign.backgroundImage = 'url()';
        placeholder.colorAndDesign.backgroundPosition = '50% 50%';
        placeholder.colorAndDesign.backgroundSize = 'cover';
        placeholder.colorAndDesign.backgroundRepeat = 'no-repeat';
      }
      if (page !== undefined) {
        page.backgroundImage = 'url()';
        page.backgroundPosition = '50% 50%';
        page.backgroundSize = 'cover';
        page.backgroundRepeat = 'no-repeat';
      }
    });
  };

  const handleBackgroundPositionChange = (aValueOne: number, aValueTwo: number) => {
    const pos = group.backgroundPosition.option.value.split(' ');
    pos[0] = aValueOne + '%';
    pos[1] = aValueTwo + '%';
    const newPos = pos[0] + ' ' + pos[1];
    runInAction(() => {
      group.backgroundPosition.option.value = newPos;
      if (placeholder !== undefined) {
        placeholder.colorAndDesign.backgroundPosition = newPos;
      }
      if (page !== undefined) {
        page.backgroundPosition = newPos;
      }
    });
    setBgPos(newPos);
    setDesignProperty(group.backgroundPosition, newPos);
  };

  const handleBackgroundSizeChange = (event: React.MouseEvent<HTMLElement>, aSize: string) => {
    if (aSize === null) return;
    setBgSize(aSize);
    let sizeValue = aSize;
    if (aSize === 'percentage') {
      sizeValue = percentageValue + '%';
    }
    setSizeValue(aSize);
    runInAction(() => {
      group.backgroundSize.option.value = sizeValue;
      if (placeholder !== undefined) {
        placeholder.colorAndDesign.backgroundSize = sizeValue;
      }
      if (page !== undefined) {
        page.backgroundSize = sizeValue;
      }
    });
    setDesignProperty(group.backgroundSize, sizeValue);
  };

  const handleBackgroundSizePercentageChange = (aValue: number) => {
    if (sizeValue != EBackgroundSize.contain.toString() || sizeValue != EBackgroundSize.cover.toString()) {
      setPercentageValue(aValue);
      setSizeValue(aValue + '%');
      runInAction(() => {
        group.backgroundSize.option.value = aValue + '%';
        if (placeholder !== undefined) {
          placeholder.colorAndDesign.backgroundSize = aValue + '%';
        }
        if (page !== undefined) {
          page.backgroundSize = aValue + '%';
        }
      });
      setDesignPropertyTimer(group.backgroundSize, aValue + '%');
    }
  };

  const handleOpenMediaLibrary = () => {
    openMediaLibraryFromEditor();
    setIsMediaLibraryOpen(true);
  };

  const handleMenu = (event: any) => {
    setMenuAnchorEl(event.currentTarget);
  };
  const handleCloseMenu = () => {
    setMenuAnchorEl(null);
  };

  const handleBGPosHorizontalChange = (event: React.MouseEvent<HTMLElement>, aValue: string) => {
    if (aValue !== null) {
      const pos = bgPos.split(' ')[1];
      handleBackgroundPositionChange(parseInt(aValue), parseInt(pos.replace('%', '')));
      setBgPosHorizontal(aValue);
    }
  };

  const handleBGPosVerticalChange = (event: React.MouseEvent<HTMLElement>, aValue: string) => {
    if (aValue !== null) {
      const pos = bgPos.split(' ')[0];
      handleBackgroundPositionChange(parseInt(pos.replace('%', '')), parseInt(aValue));
      setBgPosVertical(aValue);
    }
  };

  const handleBackgroundRepeatChange = (event: React.MouseEvent<HTMLElement>, aValue: string) => {
    if (aValue !== null) {
      setBgRepeat(aValue);
      setDesignProperty(group.backgroundRepeat, aValue);
      runInAction(() => {
        group.backgroundRepeat.option.value = aValue;
        if (placeholder !== undefined) {
          placeholder.colorAndDesign.backgroundRepeat = aValue;
        }
        if (page !== undefined) {
          page.backgroundRepeat = aValue;
        }
      });
    }
  };

  const onMouseDown = () => {
    setIsMouseDown(true);
  };

  const onMouseUp = (e: any) => {
    if (!boundingBox || !isMouseDown) return;
    setIsMouseDown(false);
    const xPos = clamp(e.clientX - boundingBox.x - 10, 5, boundingBox.width - 25);
    const yPos = clamp(e.clientY - boundingBox.y - 10, 5, boundingBox.height - 25);
    const newXPos = Math.round(((xPos + 10) / boundingBox.width) * 100);
    const newYPos = Math.round(((yPos + 10) / boundingBox.height) * 100);
    handleBackgroundPositionChange(newXPos, newYPos);
  };

  const onMouseMove = (e: any) => {
    if (isMouseDown && boundingBox !== null) {
      setXPos(clamp(e.clientX - boundingBox.x - 10, -12, boundingBox.width - 13));
      setYPos(clamp(e.clientY - boundingBox.y - 10, -12, boundingBox.height - 13));
    }
  };

  const handleOnEditClose = (s?: string) => {
    if (s) {
      selectImage(s);
    }
  };

  const handleEditImage = () => {
    const regEx = new RegExp(/\(([^)]+)\)/g);
    const match = regEx.exec(group.backgroundImage.option.value);
    if (match) {
      openImageEditor(match[1], undefined, undefined, undefined, handleOnEditClose);
    }
  };

  useEffect(() => {
    window.addEventListener('mousemove', onMouseMove, { passive: false });
    window.addEventListener('mouseup', onMouseUp, { passive: false });
    return () => {
      window.removeEventListener('mousemove', onMouseMove);
      window.removeEventListener('mouseup', onMouseUp);
    };
  }, [focusMode, isMouseDown]);

  useEffect(() => {
    const bBox = imageContainer.current?.getBoundingClientRect();
    if (bBox !== undefined) {
      setBoundingBox(bBox);
      const oldXPos = parseInt(group.backgroundPosition.option.value.split(' ')[0].replace('%', '')) / 100;
      const oldYPos = parseInt(group.backgroundPosition.option.value.split(' ')[1].replace('%', '')) / 100;
      setXPos(bBox.width * oldXPos - 10);
      setYPos(bBox.height * oldYPos - 10);
    }
  }, [focusMode]);

  useEffect(() => {
    setFocusMode(false);
  }, [bgSize]);

  useEffect(() => {
    runInAction(() => {
      if (group.backgroundPosition.option.value.split(' ').length != 2) {
        group.backgroundPosition.option.value = '50% 50%';
      }
    });

    if (group.backgroundImage.option.value != '') {
      const reg = /(?:\(['"]?)(.*?)(?:['"]?\))/;
      const result = reg.exec(group.backgroundImage.option.value);
      if (result) {
        setImage(result[1]);
      }
    }
  }, []);

  const useStyles = createUseStyles((theme: Theme) => ({
    background: {
      opacity: 0,
      position: 'absolute',
      width: '100%',
      height: '100%',
      top: 0,
      left: 0,
      backgroundColor: 'transparent',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      color: 'white',
      userSelect: 'none',
      '&:hover': {
        opacity: 1,
        background: 'rgba(0,0,0,0.4)',
      },
      '&:active': {
        background: 'rgba(0,0,0,0.6)',
      },
    },
    focusBackground: {
      position: 'absolute',
      width: '100%',
      height: '100%',
      top: 0,
      left: 0,
      background: 'rgba(0,0,0,0.4)',
    },
    focusPoint: {
      position: 'absolute',
      width: 20,
      height: 20,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      cursor: 'grab',
    },
    icon: {
      fontSize: 120,
    },
    cardMenu: {
      background: theme.palette.bgFour.main,
    },
    isPercentage: {
      color: 'transparent',
    },
    empty: {},
  }));
  const classes = useStyles({ theme });

  //extracterUrl = reg.exec(background)[1];

  //url(https://creatordev.norskinteraktiv.no/media/A5D502AE3A1D8466F4E7DDADA70DAE9A/00000003.jpg)

  /* if (backgroundImage.option.value.includes('gradient')) {
    return <></>;
  } */
  if (group.backgroundImage.option.value === '' || group.backgroundImage.option.value.includes('gradient')) {
    return (
      <>
        <Divider />
        <AddStyle styleName={'Background Image'} addStyle={handleAddBackgroundImage} />
      </>
    );
  } else {
    return (
      <>
        <Accordion title={'Background Image'} isOpen={defaultOpen}>
          <div style={{ display: 'flex', justifyContent: 'end' }}>
            <Tooltip title={'Edit background image'}>
              <IconButton onClick={handleEditImage}>
                <EditIcon />
              </IconButton>
            </Tooltip>
            <Tooltip title={'Remove background image'}>
              <IconButton onClick={handleRemoveBackgroundImage}>
                <DeleteIcon />
              </IconButton>
            </Tooltip>
          </div>
          <div style={{ paddingTop: 20, paddingLeft: 14, paddingRight: 13, display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
            <div style={{ position: 'relative', display: 'flex' }}>
              {image == '' ? (
                <>
                  <div style={{ height: 100, width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', backgroundColor: theme.palette.bgOne.main }}>
                    <Button variant='text' onClick={handleMenu}>
                      Choose Image
                    </Button>
                  </div>
                </>
              ) : (
                <>
                  {imgLoading && <Skeleton animation='wave' variant='rectangular' style={{ width: '100%', height: '100%', objectFit: 'cover', objectPosition: 'center' }} />}
                  <img
                    ref={imageContainer}
                    style={{ display: !imgLoading ? 'unset' : 'none', width: '100%', height: '100%', objectFit: 'cover', objectPosition: 'center' }}
                    src={image}
                    onLoad={() => setImgLoading(false)}
                  />

                  {!focusMode ? (
                    <div className={classes.background} onClick={handleMenu}>
                      <Typography variant='body1'>Replace</Typography>
                    </div>
                  ) : (
                    <div className={classes.focusBackground}>
                      <div className={classes.focusPoint} style={{ top: yPos, left: xPos }} onMouseDown={onMouseDown}>
                        <FilterCenterFocusIcon fontSize='large' />
                      </div>
                    </div>
                  )}
                </>
              )}
            </div>
            <Menu
              anchorEl={menuAnchorEl}
              id='media-menu'
              open={menuOpen}
              onClose={handleCloseMenu}
              onClick={handleCloseMenu}
              PaperProps={{
                elevation: 5,
                className: classes.cardMenu,
              }}
              transformOrigin={{ horizontal: 'center', vertical: 'center' }}
              anchorOrigin={{ horizontal: 'center', vertical: 'center' }}
            >
              <MenuItem onClick={handleOpenMediaLibrary}>Media Library</MenuItem>
              <MenuItem onClick={() => setIsUnsplashOpen(true)}>Unsplash</MenuItem>
            </Menu>
            {image !== '' && (
              <>
                <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', alignItems: 'center' }}>
                  <div style={{ display: 'flex', flexDirection: 'column', marginTop: 16, width: '100%', gap: 8 }}>
                    <Typography variant='body1'>{group.backgroundSize.caption}</Typography>
                    <ToggleButtonGroup color='primary' size='small' aria-label='Small sizes' value={bgSize} onChange={handleBackgroundSizeChange} exclusive fullWidth>
                      <ToggleButton value='percentage' key='percentage'>
                        <PercentIcon />
                      </ToggleButton>
                      <ToggleButton value='contain' key='contain'>
                        <AspectRatioIcon />
                      </ToggleButton>
                      <ToggleButton value='cover' key='cover'>
                        <SettingsOverscanIcon />
                      </ToggleButton>
                    </ToggleButtonGroup>
                  </div>
                  <div></div>
                </div>
                <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', marginTop: 16, gap: 8, width: '100%' }}>
                  {bgSize === 'contain' && (
                    <>
                      <Typography variant='body1'>Position</Typography>
                      <div style={{ display: 'flex', flexDirection: 'column', gap: 8 }}>
                        <ToggleButtonGroup color='primary' size='small' aria-label='Small sizes' value={bgPosHorizontal} onChange={handleBGPosHorizontalChange} exclusive fullWidth>
                          <ToggleButton value='0' key='left'>
                            <AlignHorizontalLeftIcon />
                          </ToggleButton>
                          <ToggleButton value='50' key='center'>
                            <AlignHorizontalCenterIcon />
                          </ToggleButton>
                          <ToggleButton value='100' key='right'>
                            <AlignHorizontalRightIcon />
                          </ToggleButton>
                        </ToggleButtonGroup>
                        <ToggleButtonGroup color='primary' size='small' aria-label='Small sizes' value={bgPosVertical} onChange={handleBGPosVerticalChange} exclusive fullWidth>
                          <ToggleButton value='100' key='bottom'>
                            <AlignVerticalBottomIcon />
                          </ToggleButton>
                          <ToggleButton value='50' key='center'>
                            <AlignVerticalCenterIcon />
                          </ToggleButton>
                          <ToggleButton value='0' key='top'>
                            <AlignVerticalTopIcon />
                          </ToggleButton>
                        </ToggleButtonGroup>
                        <div style={{ marginTop: 16 }}>
                          <Typography variant='body1'>Repeat</Typography>
                          <ToggleButtonGroup color='primary' size='small' aria-label='Small sizes' value={bgRepeat} onChange={handleBackgroundRepeatChange} exclusive fullWidth>
                            <ToggleButton value='no-repeat' key='no-repeat'>
                              No repeat
                            </ToggleButton>
                            <ToggleButton value='repeat' key='repeat'>
                              Repeat
                            </ToggleButton>
                          </ToggleButtonGroup>
                        </div>
                      </div>
                    </>
                  )}
                  {bgSize !== 'contain' && (
                    <>
                      <Typography variant='body1'>Position</Typography>
                      <ToggleButton color='primary' size='small' value='focus' selected={focusMode} onChange={() => setFocusMode(!focusMode)}>
                        Change Focus
                      </ToggleButton>
                    </>
                  )}
                  {bgSize === 'percentage' && (
                    <div style={{ marginTop: 16 }}>
                      <Typography variant='body1'>Zoom</Typography>
                      <TextField
                        type='number'
                        value={percentageValue}
                        aria-label='background-size'
                        InputProps={{ inputProps: { min: 1, max: 500, style: { textAlign: 'center' } }, endAdornment: '%' }}
                        onChange={(e: any) => handleBackgroundSizePercentageChange(e.target.value)}
                        autoComplete={'off'}
                        size={'small'}
                        fullWidth
                        /* style={{ width: 90 }} */
                      />
                      <div style={{ marginTop: 16 }}>
                        <Typography variant='body1'>Repeat</Typography>
                        <ToggleButtonGroup color='primary' size='small' aria-label='Small sizes' value={bgRepeat} onChange={handleBackgroundRepeatChange} exclusive fullWidth>
                          <ToggleButton value='no-repeat' key='no-repeat'>
                            No repeat
                          </ToggleButton>
                          <ToggleButton value='repeat' key='repeat'>
                            Repeat
                          </ToggleButton>
                        </ToggleButtonGroup>
                      </div>
                    </div>
                  )}
                </div>
              </>
            )}
          </div>

          <Modal
            isOpen={isMediaLibraryOpen}
            onClose={() => setIsMediaLibraryOpen(false)}
            title='Media Library'
            content={<MediaLibrary onSelect={selectImageFromMediaLibrary} acceptedFiletypes={acceptedImageTypes} />}
          />
          <Modal isOpen={isUnsplashOpen} onClose={() => setIsUnsplashOpen(false)} title='Search Unsplash' content={<UnsplashContent onSelect={selectImage} />} header={<UnsplashHeader />} />
        </Accordion>
      </>
    );
  }
});
