export const GradientList = [
  ['#bdc3c7', '#2c3e50'],
  ['#ee9ca7', '#ffdde1'],
  ['#2193b0', '#6dd5ed'],
  ['#C6FFDD', '#FBD786'],
  ['#FBD786', '#f7797d'],
  ['#0F2027', '#f7797d'],
  ['#c471ed', '#f7797d'],
  ['#373B44', '#4286f4'],
  ['#2980B9', '#6DD5FA'],
  ['#1f4037', '#99f2c8'],
  ['#f12711', '#f5af19'],
  ['#283c86', '#45a247'],
  ['#104d10', '#0f9b0f'],
  ['#DCE35B', '#45B649'],
  ['#45B649', '#2C7744'],
  ['#56ab2f', '#a8e063'],
  ['#FDC830', '#F37335'],
  ['#F2994A', '#F2C94C'],
  ['#bdc3c7', '#2c3e50'],
  ['#4ECDC4', '#556270'],
  ['#304352', '#d7d2cc'],
  ['#BBD2C5', '#BBD2C5'],
];
